import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as Incidents } from './incidents';
import { reducer as Comments } from './comments';
import { reducer as Projects } from './projects';
import { reducer as Runtime } from './runtime';
import { reducer as CommonValues } from './common-values';
import { reducer as oidc } from 'redux-oidc';

import type { History } from 'history';
import type { RootState } from './index';

const reducers = {
    runtime: Runtime,
    incidents: Incidents,
    comments: Comments,
    projects: Projects,
    commonValues: CommonValues,
    oidc
};

const rootReducer = (history: History) =>
    combineReducers<RootState>({
        ...reducers,
        router: connectRouter(history),
    });

export default rootReducer;