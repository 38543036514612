import { AppThunk } from '..';
import { CommonValuesActionType, Option, Subject } from './commonTypes';
import { apiRequest } from './../../utils/api';
import { ErrorPayload } from '../runtime';

export const actionCreators = {
    ensureIncidentStatuses: (): AppThunk<Option[] | ErrorPayload> => async (dispatch, getState) => {
        const state = getState();
        if (state.commonValues.incidentStatuses == undefined || state.commonValues.incidentStatuses.length == 0) {
            try {
                const data = await apiRequest('/api/commonvalues/incidentStatuses', getState().oidc.user) as Option[];
                dispatch({ type: CommonValuesActionType.UPDATE_INCIDENT_STATUSES, payload: data });
            } catch (error) {
                dispatch({ type: CommonValuesActionType.REQUEST_ERROR, payload: { error: `Could not retrieve incident statuses. ${error}` } });
            }
        }
    },
    ensureBreeamVersions: (): AppThunk<Option[] | ErrorPayload> => async (dispatch, getState) => {
        const state = getState();
        if (state.commonValues.breeamVersions == undefined || state.commonValues.breeamVersions.length == 0) {
            try {
                const data = await apiRequest('/api/commonvalues/breeamVersions') as Option[];
                dispatch({ type: CommonValuesActionType.UPDATE_BREEAM_VERSIONS, payload: data });
            } catch (error) {
                dispatch({ type: CommonValuesActionType.REQUEST_ERROR, payload: { error: `Could not retrieve bream versions. ${error}` } });
            }
        }
    },
    ensureBreeamSubjects: (): AppThunk<Subject[] | ErrorPayload> => async (dispatch, getState) => {
        const state = getState();
        if (state.commonValues.breeamSubjects == undefined || state.commonValues.breeamSubjects.length == 0) {
            try {
                const data = await apiRequest('/api/commonvalues/breeamSubjects') as Subject[];

                dispatch({
                    type: CommonValuesActionType.UPDATE_BREEAM_SUBJECTS,
                    payload: data.map(s => {
                        if (s.parentId) {
                            const parents = data.filter(_ => _.id == s.parentId);
                            if (parents.length > 0) {
                                s.parent = parents[0];
                            }
                        }
                        return s;
                    })
                });
            } catch (error) {
                dispatch({ type: CommonValuesActionType.REQUEST_ERROR, payload: { error: `Could not retrieve subjects. ${error}` } });
            }
        }
    },
    ensureRegions: (): AppThunk<Option[] | ErrorPayload> => async (dispatch, getState) => {
        const state = getState();
        if (state.commonValues.regions == undefined || state.commonValues.regions.length == 0) {
            try {
                const data = await apiRequest('/api/commonvalues/regions', getState().oidc.user) as Option[];
                dispatch({ type: CommonValuesActionType.UPDATE_REGIONS, payload: data });
            } catch (error) {
                dispatch({ type: CommonValuesActionType.REQUEST_ERROR, payload: { error: `Could not retrieve regions. ${error}` } });
            }
        }
    },
    ensureBuildingTypes: (): AppThunk<Option[] | ErrorPayload> => async (dispatch, getState) => {
        const state = getState();
        if (state.commonValues.buildingTypes == undefined || state.commonValues.buildingTypes.length == 0) {
            try {
                const data = await apiRequest('/api/commonvalues/buildingtypes', getState().oidc.user) as Option[];
                dispatch({ type: CommonValuesActionType.UPDATE_BUILDING_TYPES, payload: data });
            } catch (error) {
                dispatch({ type: CommonValuesActionType.REQUEST_ERROR, payload: { error: `Could not retrieve building types. ${error}` } });
            }
        }
    },
    ensureProjectTypes: (): AppThunk<Option[] | ErrorPayload> => async (dispatch, getState) => {
        const state = getState();
        if (state.commonValues.projectTypes == undefined || state.commonValues.projectTypes.length == 0) {
            try {
                const data = await apiRequest('/api/commonvalues/projecttypes', getState().oidc.user) as Option[];
                dispatch({ type: CommonValuesActionType.UPDATE_PROJECT_TYPES, payload: data });
            } catch (error) {
                dispatch({ type: CommonValuesActionType.REQUEST_ERROR, payload: { error: `Could not retrieve project types. ${error}` } });
            }
        }
    },
    ensureSizeTypes: (): AppThunk<Option[] | ErrorPayload> => async (dispatch, getState) => {
        const state = getState();
        if (state.commonValues.sizeTypes == undefined || state.commonValues.sizeTypes.length == 0) {
            try {
                const data = await apiRequest('/api/commonvalues/sizetypes', getState().oidc.user) as Option[];
                dispatch({ type: CommonValuesActionType.UPDATE_SIZE_TYPES, payload: data });
            } catch (error) {
                dispatch({ type: CommonValuesActionType.REQUEST_ERROR, payload: { error: `Could not retrieve size types. ${error}` } });
            }
        }
    },
    ensureAuditPhases: (): AppThunk<Option[] | ErrorPayload> => async (dispatch, getState) => {
        const state = getState();
        if (state.commonValues.auditPhases == undefined || state.commonValues.auditPhases.length == 0) {
            try {
                const data = await apiRequest('/api/commonvalues/auditphases', getState().oidc.user) as Option[];
                dispatch({ type: CommonValuesActionType.UPDATE_AUDIT_PHASES, payload: data });
            } catch (error) {
                dispatch({ type: CommonValuesActionType.REQUEST_ERROR, payload: { error: `Could not retrieve audit phases. ${error}` } });
            }
        }
    },
    ensureLevels: (): AppThunk<Option[] | ErrorPayload> => async (dispatch, getState) => {
        const state = getState();
        if (state.commonValues.levels == undefined || state.commonValues.levels.length == 0) {
            try {
                const data = await apiRequest('/api/commonvalues/levels', getState().oidc.user) as Option[];
                dispatch({ type: CommonValuesActionType.UPDATE_LEVELS, payload: data });
            } catch (error) {
                dispatch({ type: CommonValuesActionType.REQUEST_ERROR, payload: { error: `Could not retrieve certification levels. ${error}` } });
            }
        }
    },
    ensureFurnishedStatuses: (): AppThunk<Option[] | ErrorPayload> => async (dispatch, getState) => {
        const state = getState();
        if (state.commonValues.furnishedStatuses == undefined || state.commonValues.furnishedStatuses.length == 0) {
            try {
                const data = await apiRequest('/api/commonvalues/furnishedstatuses', getState().oidc.user) as Option[];
                dispatch({ type: CommonValuesActionType.UPDATE_FURNISHED_STATUSES, payload: data });
            } catch (error) {
                dispatch({ type: CommonValuesActionType.REQUEST_ERROR, payload: { error: `Could not retrieve furnished statuses. ${error}` } });
            }
        }
    },
};