import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

export default class Loading extends React.Component<{}> {
    public render() {
        return (
            <React.Fragment>
                <div className="loading">
                    <CircularProgress />
                </div>
            </React.Fragment >
        );
    }
}