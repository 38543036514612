import { RuntimeActionType, RuntimeState, AllowedTypes, DeleteNotificationPayload, ErrorPayload, NotificationPayload } from './commonTypes';
import { ReduxAction } from '..';
import { Reducer } from 'redux';
import { Guid } from '../../utils/utils';

const initialState: RuntimeState = { notifications: [] };

export const reducer: Reducer<RuntimeState> = (state: RuntimeState = initialState, action: ReduxAction<AllowedTypes>): RuntimeState => {
    const { notifications } = state;

    if (action.payload) {
        const { error } = (action.payload as ErrorPayload);

        if (error) {
            return {
                ...state,
                notifications: [...notifications, { id: Guid.newGuid(), text: error, type: "error" }]
            };
        }
    }

    switch (action.type) {
        case RuntimeActionType.DELETE_NOTIFICATION:
            return {
                ...state,
                notifications: [...notifications.filter(_ => _.id != (action.payload as DeleteNotificationPayload).notificationId)]
            };
        case RuntimeActionType.ADD_NOTIFICATION:
            const { type, text } = (action.payload as NotificationPayload);
            return {
                ...state,
                notifications: [...notifications, { id: Guid.newGuid(), text: text, type: type }]
            };
        default:
            return state;
    }
};
