import * as React from 'react';
import { Comment, CommentType } from '../../store/comments';
import EmailIcon from '@mui/icons-material/Email';
import MessageIcon from '@mui/icons-material/Message';
import { formatDateTime } from '../../utils/utils';
import RichText from '../application/RichText';
import ReactQuill from 'react-quill';
import { Button, CircularProgress } from '@mui/material';
import Loading from '../application/Loading';
import SendIcon from '@mui/icons-material/Send';

export interface CommentsProps {
    comments: Comment[];
    isLoading: boolean;
    onPostComment: (comment: Comment) => void;
}

export interface CommentsState {
    comments: Comment[];
    isLoading: boolean;
    text: string;
}

export default class Comments extends React.Component<CommentsProps, CommentsState> {
    constructor(props: CommentsProps) {
        super(props);

        this.state = {
            comments: props.comments || [],
            isLoading: props.isLoading,
            text: ''
        };
    }

    componentDidUpdate(prevProps: CommentsProps) {
        if (this.props.comments?.length !== prevProps.comments?.length) {
            this.setState({
                comments: this.props.comments || [],
                text: ''
            });
        }
        if (this.props.isLoading !== prevProps.isLoading) {
            this.setState({
                isLoading: this.props.isLoading 
            });
        }
    }

    private postComment(): void {
        let comment = {
            content: this.state.text,
            type: CommentType.comment
        };

        this.props.onPostComment(comment);
    }

    public render() {
        return (
            <React.Fragment>
                <div className="row post-comment mb-3">
                    <div className="col-sm-12">
                        <ReactQuill
                            theme="snow"
                            readOnly={this.state.isLoading}
                            className="form-control-input"
                            value={this.state.text}
                            onChange={(content) => this.setState({ text: content })}
                        />
                    </div>
                    <div className="col-sm-12">
                        <Button variant="contained" disabled={this.state.isLoading || !this.state.text} color="primary" onClick={() => this.postComment()} >
                            {this.state.isLoading ? (<CircularProgress className="mr-3" size={24} />) : (<SendIcon className="mr-3" />)}
                            Send
                        </Button>
                    </div>
                </div>
                {this.state.isLoading && this.state.comments.length == 0 ? (<Loading />) : this.state.comments.map(comment => (
                    <div key={ comment.id }>
                        <div className="row comment">
                            <div className="col-sm-12 comment-header">
                                {comment.type == CommentType.email ? (
                                    <EmailIcon fontSize="small" />
                                ) : (
                                        <MessageIcon fontSize="small" />
                                    )}
                                <span>{formatDateTime(comment.created)}</span>
                                <i className="divider" />
                                <span>{comment.from}</span>
                            </div>
                            <div className="col-sm-12">
                                <RichText content={comment.content} />
                            </div>
                        </div>
                    </div>
                ))}
            </React.Fragment >
        );
    }
}
