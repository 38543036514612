import * as React from 'react';
import { Route } from 'react-router';
import Layout from './components/Layout';
import Home from './components/Home';
import IncidentsListContainer from './components/incident/list/IncidentsListContainer';
import ProjectsListContainer from './components/project/list/ProjectsListContainer';
import ProjectNewFormContainer from './components/project/forms/new/ProjectNewFormContainer';
import IncidentNewFormContainer from './components/incident/forms/new/IncidentNewFormContainer';
import IncidentNewAnonFormContainer from './components/incident/forms/new/IncidentNewAnonFormContainer';
import IncidentViewFormContainer from './components/incident/forms/view/IncidentViewFormContainer';
import ProjectViewFormContainer from './components/project/forms/view/ProjectViewFormContainer';
import AssesssmentViewFormContainer from './components/certification/forms/view/AssessmentViewFormContainer';
import ReportUploadFormContainer from './components/certification/forms/uploadReport/ReportUploadFormContainer';
import AuditViewFormContainer from './components/audit/forms/view/AuditViewFormContainer';
import Callback from './components/authentication/Callback';
import Login from './components/authentication/Login';
import SilentRenew from './components/authentication/SilentRenew';
import PrivateRoute from './components/authentication/PrivateRoute';

import './custom.css'

export default () => (
    <Layout>
        <Route exact path='/' component={Home} />
        {/*<PrivateRoute path='/projects' component={ProjectsListContainer} exact />*/}
        {/*<PrivateRoute path='/projects/new' component={ProjectNewFormContainer} exact />*/}
        {/*<PrivateRoute path='/projects/details/:id' component={ProjectViewFormContainer} exact />*/}
        {/*<PrivateRoute path='/projects/details/:pid/assessment/:aid' component={AssesssmentViewFormContainer} exact/>*/}
        {/*<PrivateRoute path='/projects/details/:pid/assessment/report/new' component={ReportUploadFormContainer} exact />*/}
        {/*<PrivateRoute path='/projects/details/:pid/assessment/:aid/report/new' component={ReportUploadFormContainer} exact />*/}
        {/*<PrivateRoute path='/projects/details/:pid/assessment/:aid/audits/:id' component={AuditViewFormContainer} exact />*/}
        <PrivateRoute path='/incidents' component={IncidentsListContainer} exact />
        <PrivateRoute path='/incidents/new' component={IncidentNewFormContainer} exact />
        <PrivateRoute path='/incidents/details/:id' component={IncidentViewFormContainer} />
        <Route path='/questions/new' component={IncidentNewAnonFormContainer} exact />
        <Route path='/questions/details/:id' component={IncidentViewFormContainer} />
        <Route path="/authentication/login" component={Login} />
        <Route path="/authentication/login-callback" component={Callback} />
        <Route path="/authentication/silent-renew" component={SilentRenew} />
    </Layout>
);
