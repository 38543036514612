import * as React from 'react';
import { connect } from 'react-redux';
import { CallbackComponent } from 'redux-oidc';
import { push } from 'connected-react-router';
import userManager, {resetPasswordUrl} from '../../utils/userManager';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from "@mui/material/Stack";

class Callback extends React.PureComponent<{ dispatch: (data: any) => void}> {
    render() {
        return (
            <CallbackComponent
                userManager={userManager}
                successCallback={() => this.props.dispatch(push('/'))}
                errorCallback={error => {
                    console.error(error);
                    
                    if (error.message?.startsWith('AADB2C90118')) { //Reset password
                        window.location.href = resetPasswordUrl;
                    } else if (error.message?.toLocaleLowerCase().indexOf("no state") >= 0 && window.location.hash.indexOf('error') < 0){
                        userManager.signinSilent().finally(() => window.location.href = '/');
                    } else {
                        window.location.href = '/';
                    }
                }}
            >
                <div className="auth-callback-loading">
                    <Stack justifyContent="center" alignItems="center" spacing={2}>
                        <CircularProgress size={"4rem"} />
                        <label>Omdirigering...</label>
                    </Stack>
                </div>
            </CallbackComponent>
        );
    }
}

export default connect()(Callback);