import * as React from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import './NavMenu.css';
import { Link } from '@mui/material';
import { User } from 'oidc-client';
import { connect } from 'react-redux';
import { useSelector } from "react-redux";
import userManager, {singUpUrl} from '../utils/userManager';
import { RootState } from '../store';
import { Link as DomLink } from 'react-router-dom';

export class NavMenu extends React.PureComponent<{ user?: User, isAuthenticated?: boolean }, { isOpen: boolean }> {
    public state = {
        isOpen: false
    };

    public render() {
        const { user, isAuthenticated } = this.props;

        const onLoginButtonClick = async (e: any) => {
            e.preventDefault();
            await userManager.signinRedirect();
        };

        const onLogoutButtonClick = async (e: any) => {
            e.preventDefault();
            userManager.clearStaleState();
            userManager.removeUser();
            userManager.signoutRedirect();
        };

        return (
            <header>
                <div>
                    <Container>
                        <div className="d-inline-flex flex-row-reverse header-top">
                            <div>
                                {isAuthenticated && (<span>Hallo, {user?.profile?.name}. </span>)}
                                {!isAuthenticated && (
                                    <Link href={singUpUrl}>Første pålogging</Link>
                                )}
                                <Link href="#" 
                                    onClick={
                                        isAuthenticated ? onLogoutButtonClick : onLoginButtonClick
                                    }
                                >
                                    {isAuthenticated ? 'Logg ut' : 'Logg inn'}
                                </Link>
                            </div>
                        </div>
                    </Container>
                </div>
                <Navbar className="navbar-expand-sm navbar-toggleable-sm header-banner" light >
                    <Container>
                        <NavbarBrand tag={Link} href="/"><img className="logo" src="/images/logo_header.svg" /></NavbarBrand>
                        {isAuthenticated && (
                            <NavbarToggler onClick={this.toggle} className="mr-2" />
                        )}
                        <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={this.state.isOpen} navbar>
                            <ul className="navbar-nav flex-grow">
                                <li className="nav-item">
                                    <a className="text-dark nav-link" href="https://byggalliansen.no/">Gå tilbake til nettstedet</a>
                                </li>
                                {isAuthenticated ? (
                                    <NavItem>
                                        <NavLink tag={DomLink} className="text-dark" to="/incidents/new">Legg til sak</NavLink>
                                    </NavItem>
                                ) : (
                                    <NavItem>
                                        <NavLink tag={DomLink} className="text-dark" to="/questions/new">Legg til generelt spørsmål</NavLink>
                                    </NavItem>
                                )}
                                {isAuthenticated && (
                                    <NavItem>
                                        <NavLink tag={DomLink} className="text-dark" to="/incidents">Mine saker</NavLink>
                                    </NavItem>
                                )}
                                {/*{isAuthenticated && (*/}
                                {/*    <NavItem>*/}
                                {/*        <NavLink tag={DomLink} className="text-dark" to="/projects">Projects</NavLink>*/}
                                {/*    </NavItem>*/}
                                {/*)}*/}
                            </ul>
                        </Collapse>
                    </Container>
                </Navbar>
            </header>
        );
    }

    private toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }
}

const mapStateToProps = (state: RootState) => {
    return {
        user: state.oidc.user,
        isAuthenticated: state.oidc.user && !state.oidc.user.expired
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        dispatch
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NavMenu);
