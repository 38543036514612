import { createUserManager } from 'redux-oidc';
import { WebStorageStateStore } from 'oidc-client';
const getCookie = (name: string, defaultValue: string = ''): string => {
    const value = ('; ' + document.cookie).split(`; ${name}=`).pop()?.split(';').shift();
    return !!value ? decodeURIComponent(value) : defaultValue;
}

const appUrl = `${window.location.protocol}//${window.location.host}`;
const tenant = (window as any).tenant;
const clientId = (window as any).clientId;
const signInOutPolicy = (window as any).signInOutPolicy;
const signUpPolicy = (window as any).signUpPolicy;
const resetPasswordPolicy = (window as any).resetPasswordPolicy;
export const singUpUrl = `https://${tenant}.b2clogin.com/${tenant}.onmicrosoft.com/oauth2/v2.0/authorize?p=${signUpPolicy}&client_id=${clientId}&nonce=defaultNonce&redirect_uri=${appUrl}/authentication/login-callback&scope=openid&response_type=id_token&prompt=login`;
export const resetPasswordUrl = `https://${tenant}.b2clogin.com/${tenant}.onmicrosoft.com/oauth2/v2.0/authorize?p=${resetPasswordPolicy}&client_id=${clientId}&nonce=defaultNonce&redirect_uri=${appUrl}/authentication/login-callback&scope=openid&response_type=id_token&prompt=login`;

const userManagerConfig = {
    authority: `https://${tenant}.b2clogin.com/${tenant}.onmicrosoft.com/v2.0/.well-known/openid-configuration?p=${signInOutPolicy}`,
    post_logout_redirect_uri: appUrl,
    grantType: "authorization_code",
    client_id: clientId,
    redirect_uri: `${appUrl}/authentication/login-callback`,
    asilent_redirect_uri: `${appUrl}/authentication/silent-renew`,
    automaticSilentRenew: true,
    filterProtocolClaims: true,
    revokeAccessTokenOnSignout: true,
    response_type: "code",
    response_mode: "query",
    // response_type: "id_token",
    // extraQueryParams: {
    //     resource: "https://gbaauditorstest.onmicrosoft.com/3b5e80aa-7e45-43e4-b029-c999670a6829/Access"
    // },
    scope: `openid profile https://${tenant}.onmicrosoft.com/${clientId}/Access`,
    loadUserInfo: false,
    userStore: new WebStorageStateStore({ store: localStorage }),
    stateStore: new WebStorageStateStore({ store: localStorage }),
};

const userManager = createUserManager(userManagerConfig);

export default userManager;