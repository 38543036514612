import * as React from 'react';
import userManager from '../../utils/userManager';
import { connect } from 'react-redux';
import { RootState } from './../../store';
import { bindActionCreators } from 'redux';
import { UserState } from 'redux-oidc';
import Loading from '../application/Loading';
import {
    Route,
    Redirect
} from "react-router-dom";
import { RouteProps } from 'react-router';

type PrivateRouteProps = UserState & RouteProps;

class PrivateRoute extends React.PureComponent<PrivateRouteProps> {
    public render() {
        return (
            <React.Fragment>
                {this.props.isLoadingUser ? (
                    <Loading />
                ) : (
                        <Route {...this.props}>
                            {this.props.user ? this.props.children : (
                                <Redirect
                                    push={ true }
                                    to="/authentication/login"
                                    from={this.props.location?.pathname}
                                />
                                )}
                        </Route>                        
                    )}
            </React.Fragment>
        );
    }
}

export default connect(
    (state: RootState) => {
        const { oidc } = state;
        return {
            ...oidc
        };
    },
    (dispatch) => {
        return {
            ...bindActionCreators(
                Object.assign(
                    {},
                ), dispatch)
        };
    }
)(PrivateRoute as any); // eslint-disable-line @typescript-eslint/no-explicit-any