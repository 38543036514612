export const RuntimeActionType = {
    DELETE_NOTIFICATION: 'runtime/notifications/delete',
    ADD_NOTIFICATION: 'runtime/notifications/add'
};

export type NotificationType  = "waring" | "error" | "info";

export interface ApplicationNotification {
    id: string;
    text: string;
    type: NotificationType
}

export interface RuntimeState {
    notifications: ApplicationNotification[];
}

export interface DeleteNotificationPayload {
    notificationId: string;
}

export interface NotificationPayload {
    text: string;
    type: NotificationType;
}

export interface ErrorPayload {
    error: string;
}

export type AllowedTypes = DeleteNotificationPayload | NotificationPayload | ErrorPayload;