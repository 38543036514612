import { ProjectsState, ProjectActionType, ProjectsPayload, AuditPayload, AllowedTypes, ProjectPayload, CertificationsPayload, ApsPayload, CertificationPayload, AuditsPayload } from './commonTypes';
import { ReduxAction } from '..';
import { Reducer } from 'redux';

const initialState: ProjectsState = {
    projects: [],
    aps: [],
    isLoading: false,
    project: null,
    certifications: [],
    certification: null,
    isCertificationsLoading: false,
    projectSaved: false,
    audits: [],
    audit: null,
    isAuditsLoading: false,
    reportUploaded: false
};

export const reducer: Reducer<ProjectsState> = (state: ProjectsState = initialState, action: ReduxAction<AllowedTypes>): ProjectsState => {
    switch (action.type) {
        case ProjectActionType.RESET_TEMP_SATE:
            return {
                ...state,
                projectSaved: false,
                reportUploaded: false
            };
        case ProjectActionType.RECEIVE_APS_LIST:
            const { aps } = action.payload as ApsPayload;
            return {
                ...state,
                aps: aps
            };
        case ProjectActionType.REQUEST_PROJECT_LIST:
            return {
                ...state,
                isLoading: true,
                project: null,
                certifications: []
            };
        case ProjectActionType.RECEIVE_PROJECT_LIST:
            const { projects } = action.payload as ProjectsPayload;
            return {
                ...state,
                projects,
                isLoading: false
            };
        case ProjectActionType.REQUEST_PROJECT_ITEM:
            return {
                ...state,
                project: null,
                isLoading: true
            };
        case ProjectActionType.RECEIVE_PROJECT_ITEM:
            const { project } = action.payload as ProjectPayload;
            return {
                ...state,
                project,
                isLoading: false
            };
        case ProjectActionType.REQUEST_PROJECT_ITEM_ERROR:
            return {
                ...state,
                project: null,
                isLoading: false
            };
        case ProjectActionType.REQUEST_PROJECT_LIST_ERROR:
            return {
                ...state,
                projects: [],
                isLoading: false
            };
        case ProjectActionType.REQUEST_CERTIFICATIONS_LIST:
            return {
                ...state,
                isCertificationsLoading: true,
                certifications: []
            };
        case ProjectActionType.RECEIVE_CERTIFICATIONS_LIST:
            const { certifications } = action.payload as CertificationsPayload;
            return {
                ...state,
                certifications,
                isCertificationsLoading: false
            };
        case ProjectActionType.REQUEST_CERTIFICATIONS_LIST_ERROR:
            return {
                ...state,
                certifications: [],
                isCertificationsLoading: false
            };
        case ProjectActionType.ADD_PROJECT_ITEM:
            return {
                ...state,
                projectSaved: false,
                isLoading: true
            };
        case ProjectActionType.ADD_PROJECT_ITEM_SUCCESS:
            return {
                ...state,
                projectSaved: true,
                isLoading: false
            };
        case ProjectActionType.ADD_PROJECT_ITEM_ERROR:
            return {
                ...state,
                isLoading: false
            };
        case ProjectActionType.REQUEST_CERTIFICATION_ITEM:
            return {
                ...state,
                certification: null,
                isLoading: true
            };
        case ProjectActionType.RECEIVE_CERTIFICATION_ITEM:
            const { certification } = action.payload as CertificationPayload;
            return {
                ...state,
                certification,
                isLoading: false
            };
        case ProjectActionType.REQUEST_CERTIFICATION_ITEM_ERROR:
            return {
                ...state,
                certification: null,
                isLoading: false
            };
        case ProjectActionType.REQUEST_AUDITS_LIST:
            return {
                ...state,
                isAuditsLoading: true,
                audits: []
            };
        case ProjectActionType.RECEIVE_AUDITS_LIST:
            const { audits } = action.payload as AuditsPayload;
            return {
                ...state,
                audits,
                isAuditsLoading: false
            };
        case ProjectActionType.REQUEST_AUDITS_LIST_ERROR:
            return {
                ...state,
                audits: [],
                isAuditsLoading: false
            };
        case ProjectActionType.UPLOAD_REPORT_ITEM:
            return {
                ...state,
                reportUploaded: false,
                isLoading: true
            };
        case ProjectActionType.UPLOAD_REPORT_ITEM_SUCCESS:
            return {
                ...state,
                reportUploaded: true,
                isLoading: false
            };
        case ProjectActionType.UPLOAD_REPORT_ITEM_ERROR:
            return {
                ...state,
                isLoading: false
            };
        case ProjectActionType.REQUEST_AUDIT_ITEM:
            return {
                ...state,
                audit: null,
                isLoading: true
            };
        case ProjectActionType.RECEIVE_AUDIT_ITEM:
            const { audit } = action.payload as AuditPayload;
            return {
                ...state,
                audit,
                isLoading: false
            };
        case ProjectActionType.REQUEST_AUDIT_ITEM_ERROR:
            return {
                ...state,
                audit: null,
                isLoading: false
            };
        default:
            return state;
    }
};
