import { Attachment } from "../commonModels";
import { Project } from "../projects";
import { ErrorPayload } from "../runtime";

export const IncidentActionType = {
    RESET_TEMP_SATE: 'incidents/reset_temp_state',
    REQUEST_LIST: 'incidents/fetch',
    RECEIVE_LIST: 'incidents/receive',
    REQUEST_LIST_ERROR: 'incidents/fetch/error',
    REQUEST_ITEM: 'incidents/item/fetch',
    RECEIVE_ITEM: 'incidents/item/receive',
    REQUEST_ITEM_ERROR: 'incidents/item/fetch/error',
    REQUEST_DOCUMENTS: 'incidents/item/attachments/fetch',
    REQUEST_DOCUMENTS_SUCCESS: 'incidents/item/attachments/receive',
    REQUEST_DOCUMENTS_ERROR: 'incidents/item/attachments/fetch/error',
    ADD_ITEM: 'incidents/item/add',
    ADD_ITEM_SUCCESS: 'incidents/item/add/success',
    ADD_ITEM_ERROR: 'incidents/item/add/error',
    SEND_CODE: 'incidents/verification_code/send',
    SEND_CODE_SUCCESS: 'incidents/verification_code/success',
    SEND_CODE_ERROR: 'incidents/verification_code/error',
    UPLOAD_ATTACHMENT: 'incidents/attachment/upload',
    UPLOAD_ATTACHMENT_SUCCESS: 'incidents/attachment/upload/success',
    UPLOAD_ATTACHMENT_ERROR: 'incidents/attachment/upload/error',
    DELETE_ATTACHMENT: 'incidents/attachment/delete',
    DELETE_ATTACHMENT_SUCCESS: 'incidents/attachment/delete/success',
    DELETE_ATTACHMENT_ERROR: 'incidents/attachment/delete/error'

};

export interface IncidentsState {
    isLoading: boolean;
    attachments: Attachment[];
    attachmentsLoading: boolean;
    attachmentsAdding: boolean;
    incidents: Incident[];
    incident: Incident | null;
    incidentSaved: boolean;
    codeSent: boolean;
    error?: Error;
}

export enum IncidentType {
    GeneralQuestion = 1,
    TechnicalQuery = 2,
    BreeamInUse = 3
}

export interface Incident {
    id: string;
    number: string;
    name: string;
    auditorSuggestion: string;
    description: string;
    subject: string;
    subjectId?: string;
    status: string;
    statusId?: number;
    breeamVersion: string;
    breeamVersionId?: number;
    deadline?: Date;
    created?: Date;
    modified?: Date;
    type?: IncidentType;
    projectId?: string;
    project: Project;
}

export interface IncidentsPayload {
    incidents: Incident[];
}

export interface IncidentPayload {
    incident: Incident | null;
}

export interface AttachmentsPayload {
    attachments: Attachment[];
}

export type AllowedTypes = IncidentsPayload | ErrorPayload | IncidentPayload | AttachmentsPayload;