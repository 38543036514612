import * as React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import UploadIcon from '@mui/icons-material/Upload';
import {
    TextField,
    Link,
    Select,
    Button,
    Grid,
    Divider,
    Input,
    CircularProgress,
    Dialog,
    DialogTitle, DialogContentText, DialogActions, DialogContent
} from '@mui/material';
import { Attachment } from './../../store/commonModels';
import Loading from '../application/Loading';
import {ReactNode} from "react";
import Stack from "@mui/material/Stack";

export interface AttachmentsProps {
    attachments: Attachment[];
    loading: boolean;
    attachmentsAdding: boolean;
    onAddAttachment: (attachment: File) => void;
    onDeleteAttachment: (attachment: Attachment) => void;
    disabled?: boolean;
    
}

export interface AttachmentsState {
    attachments: Attachment[];
    loading: boolean;
    attachmentsAdding: boolean;
    showDeletingConfirm: boolean;
    currentDeletionIndex?: number;
    disabled: boolean;
}

export default class Attachments extends React.Component<AttachmentsProps, AttachmentsState> {
    constructor(props: AttachmentsProps) {
        super(props);

        this.state = {
            attachments: props.attachments || [],
            loading: props.loading,
            attachmentsAdding: props.attachmentsAdding,
            showDeletingConfirm: false,
            disabled: !!this.props.disabled
        };
    }

    componentDidUpdate(prevProps: AttachmentsProps) {
        if (this.props.attachments?.length !== prevProps.attachments?.length || JSON.stringify(this.props.attachments) !== JSON.stringify(prevProps.attachments)) {
            this.setState({
                attachments: this.props.attachments || []
            });
        }
        if (this.props.loading !== prevProps.loading) {
            this.setState({
                loading: this.props.loading
            });
        }
        if (this.props.disabled !== prevProps.disabled) {
            this.setState({
                disabled: !!this.props.disabled
            });
        }
        if (this.props.attachmentsAdding !== prevProps.attachmentsAdding) {
            this.setState({
                attachmentsAdding: this.props.attachmentsAdding
            });
        }
    }

    private handleFiles(event: any): void {
        for (let i = 0; i < event.target.files.length; i++) {
            this.props.onAddAttachment(event.target.files[i]);
        }
    }

    private deleteAttachment(index: number): void {
        this.props.onDeleteAttachment(this.state.attachments[index]);
        this.closeConfirmPopup();
    }

    private closeConfirmPopup(): void {
        this.setState({showDeletingConfirm: false, currentDeletionIndex: undefined});
    }

    public render() {
        return (
            <React.Fragment>
                <div className="row mb-3">
                    <div className="col-6">
                        <h4>Vedlegg</h4>
                    </div>
                    <div className="col-6 text-right">
                        <label htmlFor="contained-button-file">
                            <Input disabled={this.state.loading || this.state.attachmentsAdding || this.state.disabled} id="contained-button-file" hidden multiple={true} type="file" onChange={(e) => this.handleFiles(e)} />
                            <Button disabled={this.state.loading || this.state.attachmentsAdding || this.state.disabled} variant="contained" component="span">
                                {this.state.attachmentsAdding ? (<CircularProgress className="mr-3" size={24} />) : (<UploadIcon className="mr-3" />)}
                                Last opp vedlegg
                            </Button>
                        </label>
                    </div>
                </div>
                <div className="row mb-3">
                    {this.state.loading ? (<div className="col-sm-12"><Loading /></div>) : this.state.attachments.map((file, index) => (
                        <Stack direction={"row"} className="col-sm-12">
                            <Button className="icon-button" variant="text" disabled={this.state.loading || this.state.attachmentsAdding || this.state.disabled} onClick={() => this.setState({showDeletingConfirm: true, currentDeletionIndex: index})}><DeleteIcon className="mr-3" /></Button>
                            <Link href={file.url}>{file.name}</Link>
                        </Stack>
                    ))}
                </div>
                <Dialog
                    open={this.state.showDeletingConfirm}
                    onClose={() => this.setState({showDeletingConfirm: false})}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Sletting av vedlegg"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Er du sikker på at du vil slette et vedlegg?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.closeConfirmPopup()}>Nei</Button>
                        <Button onClick={() => this.deleteAttachment(this.state.currentDeletionIndex!)} autoFocus>Ja</Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment >
        );
    }
}
