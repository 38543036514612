import * as React from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../../store';
import * as CommentsStore from '../../../../store/comments';
import * as IncidentsStore from '../../../../store/incidents';
import * as CommonValuesStore from '../../../../store/common-values';
import { bindActionCreators } from 'redux';
import IncidentViewForm from './IncidentViewForm';
import { Incident } from '../../../../store/incidents/commonTypes';
import { UserState } from 'redux-oidc';
import { match } from 'react-router';
import Loading from '../../../application/Loading';
import { ConnectedRouterProps } from 'connected-react-router';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button } from '@mui/material';
import Attachments from './../../../attachments/Attachments';
import Comments from './../../../comments/Comments';
import GoBackContainer from './../../../application/GoBackContainer';
import { Attachment } from '../../../../store/commonModels';

// At runtime, Redux will merge together...
type IncidentsViewFormProps =
    IncidentsStore.IncidentsState
    & typeof IncidentsStore.actionCreators
    & { comments: CommentsStore.CommentsState }
    & typeof CommentsStore.actionCreators
    & CommonValuesStore.CommmonValuesState
    & typeof CommonValuesStore.actionCreators
    & UserState
    & ConnectedRouterProps
    & { match: match<{ id: string }> };

class IncidentViewFormContainer extends React.PureComponent<IncidentsViewFormProps> {
    // This method is called when the component is first added to the document
    public componentDidMount() {
        this.props.requestIncident(this.props.match.params.id);
        this.props.requestIncidentComments(this.props.match.params.id);
        this.props.requestIncidentAttachments(this.props.match.params.id);

    }

    public render() {
        return (
            <React.Fragment>
                <GoBackContainer title={this.props.isLoading || this.props.incident == null ? 'Laster...' : this.props.incident.name} onGoBack={() => this.props.history.goBack()}>
                    {this.props.isLoading ? (
                        <Loading />
                    ) : (this.props.incident ? (
                        <div>
                            <IncidentViewForm incident={this.props.incident} />
                                <Attachments attachmentsAdding={this.props.attachmentsAdding} loading={this.props.attachmentsLoading} attachments={this.props.attachments} onAddAttachment={(file: File) => { this.props.uploadAttachment(this.props.incident ? this.props.incident.id : '', file); }} onDeleteAttachment={(a: Attachment) => this.props.deleteAttachment(this.props.incident ? this.props.incident.id : '', a)} />
                            <h4>Kommentar</h4>
                            <Comments comments={this.props.comments.comments} isLoading={this.props.comments.isLoading} onPostComment={(comment: CommentsStore.Comment) => this.props.addIncidentComment(comment, this.props.incident?.id ?? '')} />
                        </div>
                    ) : (
                        <span>No data</span>
                    ))}
                </GoBackContainer>
            </React.Fragment>
        );
    }
}

export default connect(
    (state: RootState) => {
        const { incidents, commonValues, oidc, comments } = state;
        return {
            ...incidents,
            ...commonValues,
            ...oidc,
            comments: { ...comments }
        };
    },
    (dispatch) => {
        return {
            ...bindActionCreators(
                Object.assign(
                    {},
                    IncidentsStore.actionCreators,
                    CommentsStore.actionCreators,
                    CommonValuesStore.actionCreators
                ), dispatch)
        };
    }
)(IncidentViewFormContainer as any); // eslint-disable-line @typescript-eslint/no-explicit-any
