import * as React from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button } from '@mui/material';

export default class GoBackContainer extends React.Component<{ onGoBack: () => void, title: string }> {
    public render() {
        return (
            <React.Fragment>
                <div className="row mt-3 mb-3">
                    <Button
                        variant="text"
                        className="form-back-button"
                        onClick={(event: React.MouseEvent<HTMLButtonElement, Event>) => this.props.onGoBack()}
                        startIcon={<ArrowBackIcon />}
                    />
                    <h3 className="mt-auto mb-auto">{this.props.title}</h3>
                </div>
                <div className="data-container">
                    {this.props.children}
                </div>
            </React.Fragment>
        );
    }
}