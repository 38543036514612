import { CommmonValuesState, CommonValuesActionType, Option, AllowedTypes, Subject } from './commonTypes';
import { ReduxAction } from '..';
import { Reducer } from 'redux';

const initialState: CommmonValuesState = {
    projectStatuses: [],
    incidentStatuses: [],
    regions: [],
    breeamVersions: [],
    breeamSubjects: [],
    buildingTypes: [],
    projectTypes: [],
    sizeTypes: [],
    auditPhases: [],
    furnishedStatuses: [],
    levels: []
};

export const reducer: Reducer<CommmonValuesState> = (state: CommmonValuesState = initialState, action: ReduxAction<AllowedTypes>): CommmonValuesState => {
    switch (action.type) {
        case CommonValuesActionType.UPDATE_INCIDENT_STATUSES:
            const incidentStatuses = action.payload as Option[];
            return {
                ...state,
                incidentStatuses: incidentStatuses,
            };
        case CommonValuesActionType.UPDATE_BREEAM_VERSIONS:
            const breamVersions = action.payload as Option[];
            return {
                ...state,
                breeamVersions: breamVersions,
            };
        case CommonValuesActionType.UPDATE_BREEAM_SUBJECTS:
            const breeamSubjects = action.payload as Subject[];
            return {
                ...state,
                breeamSubjects: breeamSubjects,
            };
        case CommonValuesActionType.UPDATE_REGIONS:
            const regions = action.payload as Option[];
            return {
                ...state,
                regions: regions,
            };
        case CommonValuesActionType.UPDATE_BUILDING_TYPES:
            const buildingTypes = action.payload as Option[];
            return {
                ...state,
                buildingTypes: buildingTypes,
            };
        case CommonValuesActionType.UPDATE_PROJECT_TYPES:
            const projectTypes = action.payload as Option[];
            return {
                ...state,
                projectTypes: projectTypes,
            };
        case CommonValuesActionType.UPDATE_SIZE_TYPES:
            const sizeTypes = action.payload as Option[];
            return {
                ...state,
                sizeTypes: sizeTypes,
            };
        case CommonValuesActionType.UPDATE_AUDIT_PHASES:
            const auditPhases = action.payload as Option[];
            return {
                ...state,
                auditPhases: auditPhases,
            };
        case CommonValuesActionType.UPDATE_FURNISHED_STATUSES:
            const furnishedStatuses = action.payload as Option[];
            return {
                ...state,
                furnishedStatuses: furnishedStatuses,
            };
        case CommonValuesActionType.UPDATE_LEVELS:
            const levels = action.payload as Option[];
            return {
                ...state,
                levels: levels,
            };
        default:
            return state;
    }
};
