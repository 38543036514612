import * as React from 'react';

export default class RichText extends React.Component<{ content: string }> {
    public render() {
        return (
            <React.Fragment>
                <iframe
                    width="100%"
                    srcDoc={this.props.content}
                    frameBorder={0}
                    scrolling="no"
                    onLoad={(event: any) => {
                        event.target.style.height = event.target.contentWindow.document.documentElement.scrollHeight + 'px';
                        window.setTimeout(() => {
                            event.target.style.height = event.target.contentWindow.document.documentElement.scrollHeight + 'px';    
                        }, 1000);

                        document.head.querySelectorAll('link').forEach(link => {
                            let newLink = event.target.contentDocument.createElement("link");
                            newLink.rel  = link.rel;
                            newLink.href = link.href;
                            event.target.contentDocument.head.appendChild(newLink);
                        })
                    }
                }></iframe>
            </React.Fragment >
        );
    }
}