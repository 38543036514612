import * as React from 'react';
import { connect } from 'react-redux';
import userManager from '../../utils/userManager';

class Login extends React.PureComponent<{}> {
    render() {
        userManager.signinRedirect();
        return (
                <div>Redirecting...</div>
        );
    }
}

export default connect()(Login);