import * as React from 'react';
import { Incident } from '../../../../store/incidents/commonTypes';
import { formatDateTime, getOptionLabel, incidentTypeOptions } from '../../../../utils/utils';
import RichText from '../../../application/RichText';

export interface IncidentViewFormProps {
    incident: Incident;
}

export interface IncidentViewFormState {
}

export default class IncidentViewForm extends React.Component<IncidentViewFormProps, IncidentViewFormState> {
    constructor(props: IncidentViewFormProps) {
        super(props);

        this.state = {
        };
    }

    public render() {
        return (
            <React.Fragment>
                <div className="details">
                    <div className="row">
                        <div className="col-sm-12 col-md-3 mb-4">
                            <div>
                                <span className="label">Saksnummer</span>
                            </div>
                            <div>
                                <span className="data-value">{this.props.incident.number}</span>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-3 mb-4">
                            <div>
                                <span className="label">Type</span>
                            </div>
                            <div>
                                <span className="data-value">{getOptionLabel(incidentTypeOptions, this.props.incident.type as number)}</span>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-3 mb-4">
                            <div>
                                <span className="label">Opprettet den</span>
                            </div>
                            <div>
                                <span className="data-value">{formatDateTime(this.props.incident.created)}</span>
                            </div>
                        </div>
                        {(this.props.incident.deadline) && (
                            <div className="col-sm-12 col-md-3 mb-4">
                                <div>
                                    <span className="label">Frist</span>
                                </div>
                                <div>
                                    <span className="data-value">{formatDateTime(this.props.incident.deadline)}</span>
                                </div>
                            </div>
                        )}
                        {(this.props.incident.status) && (
                            <div className="col-sm-12 col-md-3 mb-4">
                                <div>
                                    <span className="label">Status</span>
                                </div>
                                <div>
                                    <span className="data-value">{this.props.incident.status}</span>
                                </div>
                            </div>
                        )}
                        {(this.props.incident.breeamVersion) && (
                            <div className="col-sm-12 col-md-3 mb-4">
                                <div>
                                    <span className="label">BREEAM-NOR versjon</span>
                                </div>
                                <div>
                                    <span className="data-value">{this.props.incident.breeamVersion}</span>
                                </div>
                            </div>
                        )}
                        {(this.props.incident.subject) && (
                            <div className="col-sm-12 col-md-3 mb-4">
                                <div>
                                    <span className="label">Emne</span>
                                </div>
                                <div>
                                    <span className="data-value">{this.props.incident.subject}</span>
                                </div>
                            </div>
                        )}
                        {(this.props.incident.project) && (
                            <div className="col-sm-12 col-md-3 mb-4">
                                <div>
                                    <span className="label">Prosjekt</span>
                                </div>
                                <div>
                                    <span className="data-value">{this.props.incident.project?.name}</span>
                                </div>
                            </div>
                        )}
                        <div className="col-sm-12">
                            <div>
                                <span className="label">Beskrivelse</span>
                            </div>
                            <div>
                                <RichText content={this.props.incident.description} />
                            </div>
                        </div>
                        {this.props.incident.auditorSuggestion && (
                        <div className="col-sm-12">
                            <div>
                                <span className="label">Revisors vurdering</span>
                            </div>
                            <div>
                                <RichText content={this.props.incident.auditorSuggestion} />
                            </div>
                        </div>
                        )}
                        <div className="col-sm-12">
                            <div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment >
        );
    }
}