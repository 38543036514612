import { CommentsState, CommentsActionType, CommentsPayload, AllowedTypes, CommentAddedPayload } from './commonTypes';
import { ReduxAction } from '..';
import { Reducer } from 'redux';

const initialState: CommentsState = { comments: [], isLoading: false, commentAdded: false };

export const reducer: Reducer<CommentsState> = (state: CommentsState = initialState, action: ReduxAction<AllowedTypes>): CommentsState => {
    switch (action.type) {
        case CommentsActionType.REQUEST_LIST:
            return {
                ...state,
                isLoading: true,
                commentAdded: false,
                comments: []
            };
        case CommentsActionType.RECEIVE_LIST:
            const { comments } = action.payload as CommentsPayload;
            return {
                ...state,
                comments,
                isLoading: false
            };
        case CommentsActionType.REQUEST_LIST_ERROR:
            return {
                ...state,
                comments: [],
                isLoading: false
            };
        case CommentsActionType.REQUEST_ADD_COMMENT:
            return {
                ...state,
                isLoading: true
            };
        case CommentsActionType.RECEIVE_ADD_COMMENT:
            return {
                ...state,
                isLoading: false,
                commentAdded: true
            };
        case CommentsActionType.REQUEST_ADD_COMMENT_ERROR:
            return {
                ...state,
                isLoading: false
            };
        default:
            return state;
    }
};
