import { AppThunk } from '..';
import { RuntimeActionType, AllowedTypes, NotificationType  } from './commonTypes';

export const actionCreators = {
    closeNotification: (notificationId: string): AppThunk<AllowedTypes> => async (dispatch, getState) => {
        dispatch({ type: RuntimeActionType.DELETE_NOTIFICATION, payload: { notificationId: notificationId } });
    },
    showNotification: (text: string, type: NotificationType): AppThunk<AllowedTypes> => async (dispatch, getState) => {
        dispatch({ type: RuntimeActionType.ADD_NOTIFICATION, payload: { text: text, type: type} });
    }
};