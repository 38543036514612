import * as React from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../store';
import * as RuntimeStore from '../../../store/runtime';
import { bindActionCreators } from 'redux';
import Notification from './Notification';

// At runtime, Redux will merge together...
type NotificationsProps =
    RuntimeStore.RuntimeState
    & typeof RuntimeStore.actionCreators;

class Notifications extends React.PureComponent<NotificationsProps> {
    public render() {
        return (
            <React.Fragment>
                <div className="notifications">
                    {this.props.notifications.map(e => (
                        <Notification key={e.id} notification={e} onClose={() => this.props.closeNotification(e.id)} autoClose={10000} />
                    ))}
                </div>
            </React.Fragment>
        );
    }
}

export default connect(
    (state: RootState) => {
        const { runtime } = state;
        return {
            ...runtime
        };
    },
    (dispatch) => {
        return {
            ...bindActionCreators(
                Object.assign(
                    {},
                    RuntimeStore.actionCreators
                ), dispatch)
        };
    }
)(Notifications as any); // eslint-disable-line @typescript-eslint/no-explicit-any
