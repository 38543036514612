import * as React from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../../store';
import * as IncidentsStore from '../../../../store/incidents';
import * as ProjectsStore from '../../../../store/projects';
import * as CommonValuesStore from '../../../../store/common-values';
import * as RuntimeStore from '../../../../store/runtime';
import { bindActionCreators } from 'redux';
import IncidentNewForm from './IncidentNewForm';
import { Incident } from '../../../../store/incidents/commonTypes';
import { UserState } from 'redux-oidc';
import { ConnectedRouterProps } from 'connected-react-router';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button } from '@mui/material';
import { Person } from '../../../../store/common-values/commonTypes';
import GoBackContainer from '../../../application/GoBackContainer';

// At runtime, Redux will merge together...
type IncidentsNewAnonFormProps =
    IncidentsStore.IncidentsState
    & typeof IncidentsStore.actionCreators
    & ProjectsStore.ProjectsState
    & typeof ProjectsStore.actionCreators
    & CommonValuesStore.CommmonValuesState
    & typeof CommonValuesStore.actionCreators
    & typeof RuntimeStore.actionCreators
    & ConnectedRouterProps
    & UserState;

class IncidentNewAnonFormContainer extends React.PureComponent<IncidentsNewAnonFormProps> {
    // This method is called when the component is first added to the document
    public componentDidMount() {
        this.ensureDataFetched();
    }

    componentDidUpdate(prevProps: IncidentsNewAnonFormProps) {
        //Check if item was saved
        if (this.props.incidentSaved && prevProps.incidentSaved != this.props.incidentSaved) {
            this.props.history.push(`/questions/details/${this.props.incident?.number}`);
        }
    }

    private createIncident(incident: Incident, attachments: File[], person?: Person, codeFromEmail?: string): void {
        this.props.addIncidentAnon(incident, attachments, person, codeFromEmail);
    }

    private cancel(): void {
        this.goBack();
    }

    private goBack(): void {
        if (this.props.history.length > 0)
            this.props.history.goBack();
        else
            this.props.history.replace("/");
    }

    public render() {
        return (
            <React.Fragment>
                <GoBackContainer title="Saksregistrering" onGoBack={() => this.goBack()}>
                    <IncidentNewForm
                        onSave={(i, p, c, a) => this.createIncident(i, a, p, c)}
                        onSendVerificationCode={(email) => this.props.sendVerificationCode(email)}
                        onCancel={() => this.cancel()}
                        incidentId={this.props.incident?.id}
                        incidentNumber={this.props.incident?.number}
                        isLoading={this.props.isLoading}
                        codeSent={this.props.codeSent}
                        breeamVersions={this.props.breeamVersions}
                        breeamSubjects={this.props.breeamSubjects}
                        projects={[]}
                    />
                </GoBackContainer>
            </React.Fragment>
        );
    }

    private ensureDataFetched() {
        this.props.resetFormState();
        this.props.ensureBreeamVersions();
        this.props.ensureBreeamSubjects();
    }
}

export default connect(
    (state: RootState) => {
        const { incidents, commonValues, oidc, projects } = state;
        return {
            ...projects,
            ...incidents,
            ...commonValues,
            ...oidc
        };
    },
    (dispatch) => {
        return {
            ...bindActionCreators(
                Object.assign(
                    {},
                    ProjectsStore.actionCreators,
                    IncidentsStore.actionCreators,
                    CommonValuesStore.actionCreators,
                    RuntimeStore.actionCreators
                ), dispatch)
        };
    }
)(IncidentNewAnonFormContainer as any); // eslint-disable-line @typescript-eslint/no-explicit-any
