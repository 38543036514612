import { AppThunk } from '..';
import { ProjectActionType, Project, ProjectsPayload, AllowedTypes, Certification, Audit } from './commonTypes';
import { apiRequest } from '../../utils/api';
import { Person } from '../common-values/commonTypes';
import { toBase64 } from '../../utils/utils';

export const actionCreators = {
    resetFormState: (): AppThunk<AllowedTypes> => (dispatch, getState) => {
        dispatch({ type: ProjectActionType.RESET_TEMP_SATE });
    },
    ensureAps: (): AppThunk<AllowedTypes> => async (dispatch, getState) => {
        const state = getState();
        if (state.projects.aps == undefined || state.projects.aps.length == 0) {
            try {
                const data = await apiRequest('/api/projects/aps', getState().oidc.user) as Person[];
                dispatch({ type: ProjectActionType.RECEIVE_APS_LIST, payload: { aps: data }});
            } catch (error) {
                dispatch({ type: ProjectActionType.RECEIVE_APS_ERROR, payload: { error: `Could not retrieve aps. ${error}` } });
            }
        }
    },
    requestProjects: (reload: boolean = false): AppThunk<AllowedTypes> => async (dispatch, getState) => {
        const state = getState();
        if (!reload || state.projects.projects == undefined || state.projects.projects.length == 0) {
            try {
                dispatch({ type: ProjectActionType.REQUEST_PROJECT_LIST });

                const data = await apiRequest('/api/projects', getState().oidc.user) as Project[];
                dispatch({ type: ProjectActionType.RECEIVE_PROJECT_LIST, payload: { projects: data } });
            } catch (error) {
                dispatch({ type: ProjectActionType.REQUEST_PROJECT_LIST_ERROR, payload: { error: `Could not retrieve projects. ${error}` } });
            }
        }
    },
    requestProject: (id: string): AppThunk<AllowedTypes> => async (dispatch, getState) => {
        try {
            dispatch({ type: ProjectActionType.REQUEST_PROJECT_ITEM });
            const data = await apiRequest(`/api/projects/${id}`, getState().oidc.user) as Project;
            dispatch({ type: ProjectActionType.RECEIVE_PROJECT_ITEM, payload: { project: data } });
        } catch (error) {
            dispatch({ type: ProjectActionType.REQUEST_PROJECT_ITEM_ERROR, payload: { error: `Could not retrieve project. ${error}` } });
        }
    },
    requestProjectCertifications: (id: string): AppThunk<AllowedTypes> => async (dispatch, getState) => {
        try {
            dispatch({ type: ProjectActionType.REQUEST_CERTIFICATIONS_LIST });
            const data = await apiRequest(`/api/certification/getforproject/${id}`, getState().oidc.user) as Certification[];
            dispatch({ type: ProjectActionType.RECEIVE_CERTIFICATIONS_LIST, payload: { certifications: data } });
        } catch (error) {
            dispatch({ type: ProjectActionType.REQUEST_CERTIFICATIONS_LIST_ERROR, payload: { error: `Could not retrieve project certifications. ${error}` } });
        }
    },
    addProject: (project: Project): AppThunk<AllowedTypes> => async (dispatch, getState) => {
        try {
            dispatch({ type: ProjectActionType.ADD_PROJECT_ITEM });
            await apiRequest(`/api/projects`, getState().oidc.user, project, "POST");
            dispatch({ type: ProjectActionType.ADD_PROJECT_ITEM_SUCCESS });
        } catch (error) {
            dispatch({ type: ProjectActionType.ADD_PROJECT_ITEM_ERROR, payload: { error: `Could not create project. ${error}` } });
        }
    },
    requestCertification: (id: string): AppThunk<AllowedTypes> => async (dispatch, getState) => {
        try {
            dispatch({ type: ProjectActionType.REQUEST_CERTIFICATION_ITEM });
            const data = await apiRequest(`/api/certification/${id}`, getState().oidc.user) as Certification;
            dispatch({ type: ProjectActionType.RECEIVE_CERTIFICATION_ITEM, payload: { certification: data } });
        } catch (error) {
            dispatch({ type: ProjectActionType.REQUEST_CERTIFICATION_ITEM_ERROR, payload: { error: `Could not retrieve certification. ${error}` } });
        }
    },
    requestCertificationAudits: (id: string): AppThunk<AllowedTypes> => async (dispatch, getState) => {
        try {
            dispatch({ type: ProjectActionType.REQUEST_AUDITS_LIST });
            const data = await apiRequest(`/api/certification/${id}/audits`, getState().oidc.user) as Audit[];
            dispatch({ type: ProjectActionType.RECEIVE_AUDITS_LIST, payload: { audits: data } });
        } catch (error) {
            dispatch({ type: ProjectActionType.REQUEST_AUDITS_LIST_ERROR, payload: { error: `Could not retrieve certification audits. ${error}` } });
        }
    },
    requestAudit: (id: string): AppThunk<AllowedTypes> => async (dispatch, getState) => {
        try {
            dispatch({ type: ProjectActionType.REQUEST_AUDIT_ITEM });
            const data = await apiRequest(`/api/certification/audits/${id}`, getState().oidc.user) as Audit;
            dispatch({ type: ProjectActionType.RECEIVE_AUDIT_ITEM, payload: { audit: data } });
        } catch (error) {
            dispatch({ type: ProjectActionType.REQUEST_AUDIT_ITEM_ERROR, payload: { error: `Could not retrieve audit. ${error}` } });
        }
    },
    uploadReport: (projectId: string, level: number, reportFile: File, comments?: string, certificationId?: string): AppThunk<AllowedTypes> => async (dispatch, getState) => {
        try {
            dispatch({ type: ProjectActionType.UPLOAD_REPORT_ITEM });
            const reportData = await toBase64(reportFile);
            let data = {
                projectId: projectId,
                level: level,
                comments: comments,
                reportData: reportData
            };
            await apiRequest(`/api/certification/${(certificationId ? `${certificationId}/` : '')}report`, getState().oidc.user, data, "POST");
            dispatch({ type: ProjectActionType.UPLOAD_REPORT_ITEM_SUCCESS });
        } catch (error) {
            dispatch({ type: ProjectActionType.UPLOAD_REPORT_ITEM_ERROR, payload: { error: `Could not upload report. ${error}` } });
        }
    },
};