import { Company, Person } from "../common-values/commonTypes";
import { ErrorPayload } from "../runtime";

export const ProjectActionType = {
    RESET_TEMP_SATE: 'projects/reset_temp_state',
    RECEIVE_APS_LIST: 'projects/aps/receive',
    RECEIVE_APS_ERROR: 'projects/aps/fetch/error',
    REQUEST_PROJECT_LIST: 'projects/fetch',
    RECEIVE_PROJECT_LIST: 'projects/receive',
    REQUEST_PROJECT_LIST_ERROR: 'projects/fetch/error',
    REQUEST_PROJECT_ITEM: 'projects/item/fetch',
    RECEIVE_PROJECT_ITEM: 'projects/item/receive',
    REQUEST_PROJECT_ITEM_ERROR: 'projects/item/fetch/error',
    REQUEST_CERTIFICATIONS_LIST: 'projects/item/certifications/fetch',
    RECEIVE_CERTIFICATIONS_LIST: 'projects/item/certifications/receive',
    REQUEST_CERTIFICATIONS_LIST_ERROR: 'projects/item/certifications/fetch/error',
    REQUEST_CERTIFICATION_ITEM: 'certifications/item/fetch',
    RECEIVE_CERTIFICATION_ITEM: 'certifications/item/receive',
    REQUEST_CERTIFICATION_ITEM_ERROR: 'certifications/item/fetch/error',
    REQUEST_AUDITS_LIST: 'projects/item/audits/fetch',
    RECEIVE_AUDITS_LIST: 'projects/item/audits/receive',
    REQUEST_AUDITS_LIST_ERROR: 'projects/item/audits/fetch/error',
    ADD_PROJECT_ITEM: 'projects/item/add',
    ADD_PROJECT_ITEM_SUCCESS: 'projects/item/add/success',
    ADD_PROJECT_ITEM_ERROR: 'projects/item/add/error',
    UPLOAD_REPORT_ITEM: 'reports/item/add',
    UPLOAD_REPORT_ITEM_SUCCESS: 'reports/item/add/success',
    UPLOAD_REPORT_ITEM_ERROR: 'reports/item/add/error',
    REQUEST_AUDIT_ITEM: 'audits/item/fetch',
    RECEIVE_AUDIT_ITEM: 'audits/item/receive',
    REQUEST_AUDIT_ITEM_ERROR: 'audits/item/fetch/error',
};

export interface ProjectsState {
    isLoading: boolean;
    projects: Project[];
    aps: Person[];
    project: Project | null;
    certifications: Certification[];
    certification: Certification | null;
    audits: Audit[];
    audit: Audit | null;
    isCertificationsLoading: boolean;
    isAuditsLoading: boolean;
    projectSaved: boolean;
    reportUploaded: boolean;
}

export interface Audit {
    id: string;
    statusId?: number;
    status: string;
    comments: string;
    created?: Date;
    modified?: Date;
}

export interface Certification {
    id: string;
    auditorId: string;
    qaAuditor?: Person;
    certificationLevelId?: number;
    certificationLevel: string;
    projectId?: number;
    project: Project;
    phaseId?: number;
    phase: string;
    statusId?: number;
    status: string;
    created?: Date;
    modified?: Date;
}

export interface Project {
    id: string;
    name: string;
    address: string;
    zip: string;
    city: string;
    region: string;
    regionId?: number;
    gardenNumber: string;
    useNumber: string;
    buildingNumber: string;
    amountOfBuildings?: number;
    projectNumber: string;
    auditorCompany: Company | null;
    auditorCompanyId?: string;
    customer: Company | null;
    customerId?: string;
    contactPerson: Person | null;
    contactPersonId?: string;
    ap: Person | null;
    apId?: string;
    sizeTypeId?: number;
    sizeType: string;
    realSize?: number;
    projectTypeId?: number;
    projectType: string;
    buildingTypeId?: number;
    buildingType: string;
    furnishedStatusId?: number;
    furnishedStatus: string;
    statusId?: number;
    status: string;
    breeamVersionId?: number;
    breeamVersion: string;
    auditScopeId?: number;
    auditScope: string;
    comments: string;
    registrationDate?: Date;
    numberOfAppartments?: number;
    certificateEngineeringDate?: Date;
    estimatedQaEngineeringDate: Date | null;
    engineeringClassificationId?: number;
    engineeringClassification: string;
    engineeringScope?: number;
    certificateCompletionDate?: Date;
    estimatedQaCompletionDate: Date | null;
    completionClassificationId?: number;
    completionClassification: string;
    completionScope?: number;
    confidential?: boolean;
    studyCase?: boolean;
    invoiceEmail: string;
    invoiceNumber: string;
    projectStatus: ProjectStatus;
}

export enum ProjectStatus {
    onReview = 0,
    registered = 1,
    engineeringCertificationInProgress = 2,
    engineeringCertified = 3,
    completionCertificationInProgress = 4,
    completionCertified = 5,
    cancelled = 6,
    paused = 7
}

export interface ProjectsPayload {
    projects: Project[];
}

export interface ApsPayload {
    aps: Person[];
}

export interface CertificationsPayload {
    certifications: Certification[];
}

export interface AuditsPayload {
    audits: Audit[];
}

export interface AuditPayload {
    audit: Audit;
}

export interface CertificationPayload {
    certification: Certification;
}

export interface ProjectPayload {
    project: Project;
}

export type AllowedTypes = ProjectsPayload | ErrorPayload | ProjectPayload | ApsPayload | CertificationsPayload | CertificationPayload | AuditsPayload | AuditPayload;