import { ErrorPayload } from "../runtime";

export const CommentsActionType = {
    REQUEST_LIST: 'comments/fetch',
    RECEIVE_LIST: 'comments/receive',
    REQUEST_LIST_ERROR: 'comments/fetch/error',
    REQUEST_ADD_COMMENT: 'comments/add/request',
    RECEIVE_ADD_COMMENT: 'comments/add/result',
    REQUEST_ADD_COMMENT_ERROR: 'comments/add/request/error',
};

export interface CommentsState {
    isLoading: boolean;
    comments: Comment[];
    error?: Error;
    commentAdded: boolean;
}

export enum CommentType {
    comment = 0,
    email = 1
}

export interface Comment {
    id?: string;
    title?: string;
    content: string;
    from?: string;
    to?: string;
    type?: CommentType;
    created?: Date;
}

export interface CommentsPayload {
    isLoading?: boolean;
    comments: Comment[];
}

export interface CommentAddedPayload {
    comment: Comment;
}

export type AllowedTypes = CommentsPayload | CommentAddedPayload | ErrorPayload;