import * as React from 'react';
import {Incident, IncidentType} from '../../../../store/incidents/commonTypes';
import {TextField, Select, Button, Grid, Divider, Input, Checkbox, IconButton} from '@mui/material';
import {Option, Person, Subject} from '../../../../store/common-values/commonTypes';
import {User} from 'oidc-client';
import { Autocomplete } from '@mui/material';
import {Project} from '../../../../store/projects';
import ReactQuill from "react-quill"
import 'react-quill/dist/quill.snow.css'
import {incidentTypeOptions} from '../../../../utils/utils';
import SendIcon from '@mui/icons-material/Send';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ClearIcon from '@mui/icons-material/Clear';
import CircularProgress from '@mui/material/CircularProgress';
import {Attachment} from '../../../../store/commonModels';
import Attachments from '../../../attachments/Attachments';
import {TreeItem, TreeView} from "@mui/x-tree-view";
import Stack from "@mui/material/Stack";

export interface IncidentNewFormProps {
    onSave: (incident: Incident, person: Person, codeFromEmail: string, attachments: File[]) => void;
    onSendVerificationCode?: (email?: string) => void;
    onCancel: () => void;
    currentUser?: User;
    breeamVersions: Option[];
    breeamSubjects: Subject[];
    projects: Project[];
    isLoading: boolean;
    codeSent?: boolean;
    incidentId?: string;
    incidentNumber?: string;
}

export interface IncidentNewFormState {
    incident: Incident;
    breeamVersions: Option[];
    breeamSubjects: Subject[];
    attachments: File[];
    projects: Project[];
    person: Person;
    codeFromEmail: string;
    isLoading: boolean;
    codeSent?: boolean;
    showSubjects: boolean;
}

const DESCRIPION_LIMIT: number = 1048576;

export default class IncidentNewForm extends React.Component<IncidentNewFormProps, IncidentNewFormState> {
    constructor(props: IncidentNewFormProps) {
        super(props);

        this.state = {
            incident: {
                type: IncidentType.GeneralQuestion,
                breeamVersion: '',
                id: this.props.incidentId
            } as Incident,
            person: {} as Person,
            attachments: [],
            codeFromEmail: '',
            breeamVersions: props.breeamVersions,
            breeamSubjects: props.breeamSubjects,
            projects: props.projects,
            isLoading: props.isLoading,
            codeSent: props.codeSent,
            showSubjects: false
        };
    }

    componentDidUpdate(prevProps: IncidentNewFormProps) {
        if (this.props.breeamVersions?.length !== prevProps.breeamVersions?.length) {
            this.setState({breeamVersions: this.props.breeamVersions || []});
        }
        if (this.props.breeamSubjects?.length !== prevProps.breeamSubjects?.length) {
            this.setState({breeamSubjects: this.props.breeamSubjects || []});
        }
        if (this.props.projects?.length !== prevProps.projects?.length) {
            this.setState({projects: this.props.projects || []});
        }
        if (this.props.isLoading !== prevProps.isLoading) {
            this.setState({isLoading: this.props.isLoading});
        }
        if (this.props.codeSent !== prevProps.codeSent) {
            this.setState({codeSent: this.props.codeSent});
        }
        if (this.props.incidentId !== prevProps.incidentId || this.props.incidentNumber !== prevProps.incidentNumber) {
            this.setState({incident: {...this.state.incident, id: this.props.incidentId!, number: this.props.incidentNumber!}});
        }
    }

    componentDidMount() {
        if (this.props.currentUser) {
            this.updateState(s => s.incident.type == IncidentType.GeneralQuestion);
        }
    }

    private isValid(): boolean {
        if (!this.props.currentUser && !this.state.person?.email)
            return false;

        if (!this.props.currentUser && !this.state.person?.firstName)
            return false;

        if (!this.props.currentUser && !this.state.person?.lastName)
            return false;

        if (!this.props.currentUser && !this.state.codeFromEmail)
            return false;

        if (this.state.incident.type == IncidentType.TechnicalQuery && !this.state.incident.projectId)
            return false;

        if (!this.state.incident.type)
            return false;

        if (this.state.incident.type == IncidentType.TechnicalQuery && !this.state.incident.auditorSuggestion)
            return false;

        if (this.state.incident.type == IncidentType.TechnicalQuery && !this.state.incident.breeamVersionId)
            return false;

        if (!this.state.incident.name)
            return false;

        if (!this.state.incident.description)
            return false;

        if (this.state.incident.description?.length > DESCRIPION_LIMIT)
            return false;

        return true;
    }

    private drawSubjectTreeNode(subject: Subject) {
        let childrenSubjects = this.state.breeamSubjects.filter(_ => _.parentId == subject.id);
        return (
            <TreeItem nodeId={subject.id} label={<>
                    <Checkbox
                        checked={this.state.incident.subjectId == subject.id}
                        disabled={this.state.isLoading}
                        tabIndex={-1}
                        disableRipple
                        onClick={(event) => this.selectSubject(subject)}
                    />
                    {subject.title}
                </>
            }>
                {childrenSubjects.map(_ => this.drawSubjectTreeNode(_))}
            </TreeItem>
        );
    }

    private selectSubject(subject: Subject) {
        this.updateState(s => {
            s.incident.subjectId = subject.id;
            s.incident.subject = subject.title;
            s.showSubjects = false;
        });
    }

    private drawSubjects() {
        let subjects = this.state.breeamSubjects.filter(_ => !_.parentId && _.title.toLowerCase().indexOf('breeam in-use'));
        return (
            <TreeView
                defaultCollapseIcon={<ExpandMoreIcon/>}
                defaultExpandIcon={<ChevronRightIcon/>}
            >
                {subjects.map(_ => this.drawSubjectTreeNode(_))}
            </TreeView>
        );
    }

    private setField(event: any, setValue: (s: IncidentNewFormState, value: any) => void) {
        const {target} = event;
        this.updateState(s => setValue(s, target?.value));
    }

    private updateState(action: (s: IncidentNewFormState) => void): void {
        this.setState(s => {
            action(s);
            return s;
        });
    }

    public render() {
        return (
            <React.Fragment>
                {this.props.currentUser && (
                    <div className="row mb-3">
                        <div className="col-sm-12">
                            <span>Type</span><span className="required">*</span>
                        </div>
                        <div className="col-sm-12">
                            <Autocomplete
                                options={incidentTypeOptions}
                                disabled={this.state.isLoading}
                                className="form-control-input"
                                getOptionLabel={(option: any) => option.label}
                                onChange={(e, type) => this.updateState(s => s.incident.type = type?.id)}
                                renderInput={(params) => <TextField {...params} variant="standard"
                                                                    placeholder="Valg type"/>}
                            />
                        </div>
                    </div>
                )}
                {!this.props.currentUser && (
                    <div className="row mb-3">
                        <div className="col-sm-12">
                            <span>Fornavn</span><span className="required">*</span>
                        </div>
                        <div className="col-sm-12">
                            <TextField
                                placeholder="Fornavn"
                                variant="standard"
                                disabled={this.state.isLoading}
                                className="form-control-input"
                                value={this.state.person.firstName}
                                onChange={(event: any) => this.setField(event, (s, v) => s.person.firstName = v)}
                                fullWidth
                            />
                        </div>
                    </div>
                )}
                {!this.props.currentUser && (
                    <div className="row mb-3">
                        <div className="col-sm-12">
                            <span>Etternavn</span><span className="required">*</span>
                        </div>
                        <div className="col-sm-12">
                            <TextField
                                placeholder="Etternavn"
                                variant="standard"
                                disabled={this.state.isLoading}
                                className="form-control-input"
                                value={this.state.person.lastName}
                                onChange={(event: any) => this.setField(event, (s, v) => s.person.lastName = v)}
                                fullWidth
                            />
                        </div>
                    </div>
                )}
                {!this.props.currentUser && (
                    <div className="row mb-3">
                        <div className="col-sm-12">
                            <span>Epost</span><span className="required">*</span>
                        </div>
                        <div className="col-sm-10">
                            <TextField
                                placeholder="Epost"
                                variant="standard"
                                disabled={this.state.isLoading}
                                className="form-control-input"
                                value={this.state.person.email}
                                onChange={(event: any) => this.setField(event, (s, v) => s.person.email = v)}
                                fullWidth
                            />
                        </div>
                        <Button className="col-sm-2" variant="contained"
                                disabled={this.state.isLoading || !this.state.person?.email} color="primary"
                                onClick={() => this.props.onSendVerificationCode?.call(this, this.state.person?.email)}>
                            {this.state.isLoading ? (<CircularProgress className="mr-3" size={24}/>) : (
                                <SendIcon className="mr-3"/>)}
                            {this.state.codeSent ? 'Send kode på nytt' : 'Send kode'}
                        </Button>
                    </div>
                )}
                {!this.props.currentUser && this.state.codeSent && (
                    <div className="row mb-3">
                        <div className="col-sm-12">
                            <span>Kode fra epost</span><span className="required">*</span>
                        </div>
                        <div className="col-sm-12">
                            <TextField
                                placeholder="Kode fra epost"
                                variant="standard"
                                disabled={this.state.isLoading}
                                className="form-control-input"
                                value={this.state.codeFromEmail}
                                onChange={(event: any) => this.setField(event, (s, v) => s.codeFromEmail = v)}
                                fullWidth
                            />
                        </div>
                    </div>
                )}
                {this.props.currentUser && this.state.incident.type == IncidentType.TechnicalQuery && (
                    <div className="row mb-3">
                        <div className="col-sm-12">
                            <span>Prosjekt</span><span className="required">*</span>
                        </div>
                        <div className="col-sm-12">
                            <Autocomplete
                                options={this.state.projects}
                                disabled={this.state.isLoading}
                                className="form-control-input"
                                getOptionLabel={(option: Project) => `${option.projectNumber} - ${option.name}`}
                                onChange={(e, project) => this.updateState(s => {
                                    s.incident.projectId = project?.id;
                                    if (project?.breeamVersionId) {
                                        s.incident.breeamVersionId = project?.breeamVersionId;
                                    }
                                })}
                                renderInput={(params) => <TextField {...params} variant="standard"
                                                                    placeholder="Valg prosjekt"/>}
                            />
                        </div>
                    </div>
                )}
                <div className="row mb-3">
                    <div className="col-sm-12">
                        <span>BREEAM-NOR versjon</span>{this.state.incident.type == IncidentType.TechnicalQuery && (<span className="required">*</span>)}
                    </div>
                    <div className="col-sm-12">
                        <Autocomplete
                            options={this.state.breeamVersions}
                            className="form-control-input"
                            value={this.state.incident.breeamVersionId ? this.state.breeamVersions.filter(_ => _.id == this.state.incident.breeamVersionId)[0] : null}
                            disabled={this.state.isLoading}
                            getOptionLabel={(option: Option) => option.label}
                            onChange={(e, version) => this.updateState(s => {
                                s.incident.breeamVersionId = version?.id;
                                s.incident.subjectId = undefined;
                            })}
                            renderInput={(params) => <TextField {...params} variant="standard"
                                                                placeholder="Valg BREEAM-NOR versjon"/>}
                        />
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-sm-12">
                        <span>Emne</span>
                    </div>
                    <div className="col-sm-12">
                        <Stack direction="row">
                            <TextField
                                placeholder="Velg emne"
                                variant="standard"
                                disabled={true}
                                className="form-control-input"
                                fullWidth
                                onClick={() => this.setState({showSubjects: true})}
                                value={this.state.incident.subject}
                            />
                            {this.state.showSubjects ? (<KeyboardDoubleArrowUpIcon
                                    disabled={this.state.isLoading}
                                    onClick={() => this.setState({showSubjects: false})}
                                />) :
                                (<AccountTreeIcon
                                    disabled={this.state.isLoading}
                                    onClick={() => this.setState({showSubjects: true})}
                                />)}

                            <ClearIcon
                                disabled={this.state.isLoading}
                                onClick={() => this.updateState(s => {
                                    s.incident.subject = '';
                                    s.incident.subjectId = undefined;
                                })}/>
                        </Stack>
                        {this.state.showSubjects && this.drawSubjects()}
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-sm-12">
                        <span>Sakstittel</span><span className="required">*</span>
                    </div>
                    <div className="col-sm-12">
                        <TextField
                            placeholder="Sakstittel"
                            variant="standard"
                            disabled={this.state.isLoading}
                            className="form-control-input"
                            value={this.state.incident.name}
                            onChange={(event: any) => this.setField(event, (s, v) => s.incident.name = v)}
                            fullWidth
                        />
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-sm-12">
                        <span>Beskrivelse</span><span className="required">*</span>
                    </div>
                    <div className="col-sm-12">
                        <ReactQuill
                            theme="snow"
                            disabled={this.state.isLoading}
                            className="form-control-input"
                            onChange={(content) => this.updateState(s => s.incident.description = content)}
                        />
                        <p>{(this.state.incident.description?.length ? this.state.incident.description?.length : 0)}/{DESCRIPION_LIMIT}</p>
                        <p>{this.state.incident.description?.length > DESCRIPION_LIMIT && (
                            <span className="required">Max grense på alle vedlegg er 1 MB. Vennligst send bildet som vedlegg i saken.</span>)}</p>
                    </div>
                </div>
                {this.state.incident.type == IncidentType.TechnicalQuery && (
                    <div className="row mb-3">
                        <div className="col-sm-12">
                            <span>Revisors vurdering</span><span className="required">*</span>
                        </div>
                        <div className="col-sm-12">
                            <TextField
                                placeholder="Revisors vurdering"
                                variant="standard"
                                rows={5}
                                multiline={true}
                                disabled={this.state.isLoading}
                                className="form-control-input"
                                value={this.state.incident.auditorSuggestion}
                                onChange={(event: any) => this.setField(event, (s, v) => s.incident.auditorSuggestion = v)}
                                fullWidth
                            />
                        </div>
                    </div>
                )}
                <div>
                    <Attachments attachmentsAdding={false} disabled={this.state.isLoading} loading={false}
                                 attachments={this.state.attachments.map(_ => new Attachment(_.name, ''))}
                                 onAddAttachment={(file: File) => {
                                     this.setState({attachments: [...this.state.attachments, file]})
                                 }}
                                 onDeleteAttachment={(a: Attachment) => this.setState({attachments: this.state.attachments.filter(_ => _.name != a.name)})}/>
                </div>
                <div className="row mb-3">
                    <div className="col-sm-12">
                        <Button className="mr-3" variant="contained" disabled={this.state.isLoading || !this.isValid()}
                                color="primary"
                                onClick={() => this.props.onSave(this.state.incident, this.state.person, this.state.codeFromEmail, this.state.attachments)}>
                            {this.state.isLoading ? (<CircularProgress className="mr-3" size={24}/>) : (
                                <SaveIcon className="mr-3"/>)}
                            Send
                        </Button>
                        <Button variant="contained" disabled={this.state.isLoading}
                                onClick={() => this.props.onCancel()}>Avbryt</Button>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}