import * as React from 'react';
import { connect } from 'react-redux';
import { processSilentRenew } from 'redux-oidc';

class SilentRenew extends React.PureComponent<{ dispatch: (data: any) => void}> {
    render() {
        processSilentRenew();
        return (
                <div></div>
        );
    }
}

export default connect()(SilentRenew);