import { User } from 'oidc-client';

export function apiRequest(url: string, user?: User, data?: any, method = "GET", contentType = "application/json") {
    const token = user?.access_token;
    const headers = new Headers();
    if (contentType) {
        headers.append("Content-Type", contentType);
    }

    if (token) {
        headers.append("Authorization", `Bearer ${token}`);
    }

    const options: RequestInit = {
        method,
        headers
    };

    if (data) {
        options.body = contentType == "application/json" ? JSON.stringify(data) : data;
    }

    return fetch(url, options)
        .then(async (response: Response) => {
            if (response.ok) {
                return response.json().catch(() => null);
            }

            switch (response.status) {
                case 401:
                    throw `Unauthorized exception`;
                case 480:
                    const error = await response.text();
                    throw error;
                default:
                    throw "Unknown error occured"
            }
        });
}