import * as React from 'react';
import {Container} from 'reactstrap';
import NavMenu from './NavMenu';
import Notifications from './application/notifications/Notifications';
import {ThemeProvider} from '@mui/material';
import {createTheme} from '@mui/material/styles';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';

const theme = createTheme({
    palette: {
        primary: {
            main: "#00534C",
        },
        secondary: {
            main: '#139872',
        }
    },
    typography: {
        "fontFamily": `"Fira Sans", "Arial", "Helvetica Neue", "Helvetica", sans-serif`
    }
});

export default class Layout extends React.PureComponent<{}, { children?: React.ReactNode }> {
    public render() {
        return (
            <React.Fragment>
                <ThemeProvider theme={theme}>
                    <NavMenu/>
                    <Container className="p-3 content-container">
                        {this.props.children}
                    </Container>
                    <Notifications/>
                    <footer>
                        <div className="container">
                            <div>
                                <aside id="footer-sidebar-1" className="core-col">
                                    <div className="widget widget_media_image">
                                        <img width="1"
                                             height="1"
                                             src="https://byggalliansen.no/wp-content/uploads/2018/11/footer-logo.svg"
                                             className="image wp-image-2142  attachment-medium size-medium"
                                             alt=""
                                             decoding="async"
                                             loading="lazy"
                                        />
                                    </div>
                                    <div className="widget widget_block widget_media_image">
                                        <figure className="wp-block-image size-full is-resized">
                                            <img decoding="async"
                                                 loading="lazy"
                                                 src="https://byggalliansen.no/wp-content/uploads/2022/10/grå-miljofyrtarn.png"
                                                 alt="Grønn Byggallianse er sertifisert som Miljøfyrtårn"
                                                 className="wp-image-37252"
                                                 width="555"
                                                 height="472"
                                                 srcSet="https://byggalliansen.no/wp-content/uploads/2022/10/grå-miljofyrtarn.png 555w, https://byggalliansen.no/wp-content/uploads/2022/10/grå-miljofyrtarn-300x255.png 300w"
                                                 sizes="(max-width: 555px) 100vw, 555px"
                                            />
                                        </figure>
                                    </div>
                                </aside>
                                <aside id="footer-sidebar-2">
                                    <div className="widget widget_text"><h2>Finn oss</h2>
                                        <div className="textwidget"><p>Kristian Augusts gate 13, 0164 Oslo</p>
                                            <p>Organisasjonsnummer: 987297689</p>
                                            <p>Faktura: EHF</p>
                                        </div>
                                    </div>
                                </aside>
                                <aside id="footer-sidebar-3" className="core-col">
                                    <div className="widget widget_nav_menu" id="nav_menu-2">
                                        <h2>Finn frem</h2>
                                        <div className="menu-snarveier-til-footer-container">
                                            <ul id="menu-snarveier-til-footer" className="menu">
                                                <li id="menu-item-18277"
                                                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-18277">
                                                    <a
                                                        href="https://byggalliansen.no/kontakt/">Kontakt</a></li>
                                                <li id="menu-item-18276"
                                                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-18276">
                                                    <a
                                                        href="https://byggalliansen.no/hjem/bli-medlem/">Bli medlem</a>
                                                </li>
                                                <li id="menu-item-18279"
                                                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-18279">
                                                    <a
                                                        href="https://byggalliansen.no/hjem/om-oss/">Om oss</a></li>
                                                <li id="menu-item-18278"
                                                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-18278">
                                                    <a
                                                        href="https://byggalliansen.no/kontakt/kontakt-presse-og-grafikk/">Presse
                                                        og
                                                        logo</a></li>
                                                <li id="menu-item-18280"
                                                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-privacy-policy menu-item-18280">
                                                    <a rel="privacy-policy"
                                                       href="https://byggalliansen.no/hjem/om-oss/personvernerklaering/">Personvernerklæring</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </aside>
                                <aside id="footer-sidebar-4" className="core-col">
                                    <div className="widget widget_nav_menu" id="nav_menu-3">
                                        <h2>Følg oss</h2>
                                        <div className="menu-folg-oss-container">
                                            <ul id="menu-folg-oss" className="menu">
                                                <li>
                                                    <a
                                                        href="https://byggalliansen.us15.list-manage.com/subscribe?u=9c610fe46b9d689c8fb71de62&amp;id=d3db7b8fc4">Meld
                                                        deg på nyhetsbrev</a></li>
                                                <li>
                                                    <a href="https://www.linkedin.com/company/norwegian-green-building-council/">
                                                        LinkedIn
                                                        <span className="icon-container">
                                                            <LinkedInIcon className="icon"/>
                                                        </span>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="https://www.facebook.com/gronnbyggallianse/">
                                                        Facebook
                                                        <span className="icon-container">
                                                            <FacebookIcon className="icon"/>
                                                        </span>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="https://twitter.com/NorwayGBC">
                                                        Twitter
                                                        <span className="icon-container">
                                                            <TwitterIcon className="icon"/>
                                                        </span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </aside>
                            </div>
                        </div>
                    </footer>
                </ThemeProvider>
            </React.Fragment>
        );
    }
}