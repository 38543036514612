import { GridValueFormatterParams, GridValueGetterParams, GridCellParams } from '@mui/x-data-grid';
import moment from 'moment';
import { Option } from '../store/common-values';
import { IncidentType } from '../store/incidents';
import {Project, ProjectStatus} from '../store/projects/commonTypes';

export const sleep = (delay = 0) => {
    return new Promise((resolve) => {
        setTimeout(resolve, delay);
    });
};
export const toBase64 = (file: File) => new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
        const res = reader.result as string;
        resolve(res.substring(res.indexOf('base64,')+7));
    };
    reader.onerror = error => reject(error);
});
export const getOptionLabel = (options: Option[], value: number) => {
    let opt = options.filter(_ => _.id == value);
    return opt.length > 0 ? opt[0].label : '';
}
export const incidentTypeOptions: Option[] = [
    { id: IncidentType.GeneralQuestion, label: 'Generelt spørsmål' },
    { id: IncidentType.TechnicalQuery, label: 'Teknisk avklaring' }
];
export const projectStatusOptions: Option[] = [
    { id: ProjectStatus.onReview, label: 'On review' },
    { id: ProjectStatus.registered, label: 'Registered' },
    { id: ProjectStatus.engineeringCertificationInProgress, label: 'Prosjektering' },
    { id: ProjectStatus.engineeringCertified, label: 'Prosjektering Sertifisert' },
    { id: ProjectStatus.completionCertificationInProgress, label: 'Ferdigstillelse' },
    { id: ProjectStatus.completionCertified, label: 'Ferdigstillelse Sertifisert' },
    { id: ProjectStatus.cancelled, label: 'Cancelled' },
    { id: ProjectStatus.paused, label: 'Paused' },
];
export const formatDate = (date: any) => moment(date).format('DD.MM.yyyy');
export const formatDateTime = (date: any) => moment(date).format('DD.MM.yyyy HH:mm');
export const dateTimeFormatter = (params: GridValueFormatterParams) => moment(params.value as string).format('DD.MM.yyyy HH:mm');

export const projectFormatter = (params: GridValueFormatterParams) => (params.value as Project)?.name;
export const projectGetter = (params: GridValueGetterParams) => (params.value as Project)?.name;
export const projectStatusFormatter = (params: GridValueFormatterParams) => {
    const status = params.value as ProjectStatus;

    return projectStatusOptions.filter(_ => _.id == status)[0].label;
}

export const typeFormatter = (params: GridValueFormatterParams) => {
    const status = params.value as IncidentType;

    return incidentTypeOptions.filter(_ => _.id == status)[0].label;
}
export const dateTimeGetter = (params: GridValueGetterParams) => moment(params.value as string).toDate();
export class Guid {
    static newGuid() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
            let r = Math.random() * 16 | 0,
                v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }
}