import { IncidentsState, IncidentActionType, IncidentsPayload, AllowedTypes, IncidentPayload, AttachmentsPayload } from './commonTypes';
import { ReduxAction } from '..';
import { Reducer } from 'redux';

const initialState: IncidentsState = { attachmentsAdding:false, attachmentsLoading: false, incidents: [], isLoading: false, incident: null, incidentSaved: false, codeSent: false, attachments: [] };

export const reducer: Reducer<IncidentsState> = (state: IncidentsState = initialState, action: ReduxAction<AllowedTypes>): IncidentsState => {
    switch (action.type) {
        case IncidentActionType.RESET_TEMP_SATE:
            return {
                ...state,
                incidentSaved: false,
                codeSent: false
            };  
        case IncidentActionType.REQUEST_LIST:
            return {
                ...state,
                isLoading: true
            };        
        case IncidentActionType.RECEIVE_LIST:
            const { incidents } = action.payload as IncidentsPayload;
            return {
                ...state,
                incidents,
                isLoading: false
            };
        
        case IncidentActionType.REQUEST_LIST_ERROR:
            return {
                ...state,
                incidents: [],
                isLoading: false
            };
        case IncidentActionType.REQUEST_ITEM:
            return {
                ...state,
                incident: null,
                isLoading: true
            };
        case IncidentActionType.RECEIVE_ITEM:
            const { incident } = action.payload as IncidentPayload;
            return {
                ...state,
                incident,
                isLoading: false
            };
        case IncidentActionType.REQUEST_ITEM_ERROR:
            return {
                ...state,
                incident: null,
                isLoading: false
            };
        case IncidentActionType.ADD_ITEM:
            return {
                ...state,
                incidentSaved: false,
                isLoading: true
            };
        case IncidentActionType.ADD_ITEM_SUCCESS:
            return {
                ...state,
                incidentSaved: true,
                isLoading: false,
                incident: (action.payload as IncidentPayload).incident
            };
        case IncidentActionType.ADD_ITEM_ERROR:
            return {
                ...state,
                isLoading: false,
                incident: (action.payload as IncidentPayload).incident
            };
        case IncidentActionType.SEND_CODE:
            return {
                ...state,
                codeSent: false,
                isLoading: true
            };
        case IncidentActionType.SEND_CODE_SUCCESS:
            return {
                ...state,
                codeSent: true,
                isLoading: false
            };
        case IncidentActionType.SEND_CODE_ERROR:
            return {
                ...state,
                isLoading: false
            };
        case IncidentActionType.UPLOAD_ATTACHMENT:
            return {
                ...state,
                attachmentsAdding: true
            };
        case IncidentActionType.UPLOAD_ATTACHMENT_SUCCESS:
            return {
                ...state,
                attachments: [...(action.payload as AttachmentsPayload).attachments],
                attachmentsAdding: false
            };
        case IncidentActionType.UPLOAD_ATTACHMENT_ERROR:
            return {
                ...state,
                attachmentsAdding: false
            };
        case IncidentActionType.DELETE_ATTACHMENT:
            return {
                ...state,
                attachmentsAdding: true
            };
        case IncidentActionType.DELETE_ATTACHMENT_SUCCESS:
            return {
                ...state,
                attachments: [...(action.payload as AttachmentsPayload).attachments],
                attachmentsAdding: false
            };
        case IncidentActionType.DELETE_ATTACHMENT_ERROR:
            return {
                ...state,
                attachmentsAdding: false
            };            
        case IncidentActionType.REQUEST_DOCUMENTS:
            return {
                ...state,
                attachments: [],
                attachmentsLoading: true
            };
        case IncidentActionType.REQUEST_DOCUMENTS_SUCCESS:
            return {
                ...state,
                attachments: [...(action.payload as AttachmentsPayload).attachments],
                attachmentsLoading: false
            };
        case IncidentActionType.REQUEST_DOCUMENTS_ERROR:
            return {
                ...state,
                attachments: [],
                attachmentsLoading: false
            };
        default:
            return state;
    }
};
