import {AppThunk} from '..';
import {AllowedTypes, Incident, IncidentActionType} from './commonTypes';
import {apiRequest} from '../../utils/api';
import {Person} from '../common-values/commonTypes';
import {Attachment} from "../commonModels";

export const actionCreators = {
    resetFormState: (): AppThunk<AllowedTypes> => (dispatch, getState) => {
        dispatch({type: IncidentActionType.RESET_TEMP_SATE});
    },
    requestIncidents: (reload: boolean = false): AppThunk<AllowedTypes> => async (dispatch, getState) => {
        const state = getState();
        if (!reload || state.incidents.incidents == undefined || state.incidents.incidents.length == 0) {
            try {
                dispatch({type: IncidentActionType.REQUEST_LIST});
                const data = await apiRequest('/api/incidents', getState().oidc.user) as Incident[];
                dispatch({type: IncidentActionType.RECEIVE_LIST, payload: {incidents: data}});
            } catch (error) {
                dispatch({
                    type: IncidentActionType.REQUEST_LIST_ERROR,
                    payload: {error: `Kunne ikke hente saker. ${error}`}
                });
            }
        }
    },
    requestIncident: (number: string): AppThunk<AllowedTypes> => async (dispatch, getState) => {
        try {
            dispatch({type: IncidentActionType.REQUEST_ITEM});
            const user = getState().oidc.user;
            const requestUrl = user ? `/api/incidents/${number}` : `/api/incidents/anon/${number}`;
            const data = await apiRequest(requestUrl, user) as Incident;
            dispatch({type: IncidentActionType.RECEIVE_ITEM, payload: {incident: data}});
        } catch (error) {
            dispatch({
                type: IncidentActionType.REQUEST_ITEM_ERROR,
                payload: {error: `Kunne ikke hente saker. ${error}`}
            });
        }
    },
    requestIncidentAttachments: (number: string): AppThunk<AllowedTypes> => async (dispatch, getState) => {
        try {
            dispatch({type: IncidentActionType.REQUEST_DOCUMENTS});
            const user = getState().oidc.user;
            const requestUrl = user ? `/api/incidents/${number}/documents` : `/api/incidents/anon/${number}/documents`;
            const attachments = await apiRequest(requestUrl, user) as Attachment[];
            attachments.forEach(a => {
                a.url = `/api/incidents${(user ? '' : '/anon')}/${number}/documents/${a.name}`;
            });
            dispatch({type: IncidentActionType.REQUEST_DOCUMENTS_SUCCESS, payload: {attachments: attachments}});
        } catch (error) {
            dispatch({
                type: IncidentActionType.REQUEST_DOCUMENTS_ERROR,
                payload: {error: `Kunne ikke hente vedlegg. ${error}`}
            });
        }
    },
    sendVerificationCode: (email?: string): AppThunk<AllowedTypes> => async (dispatch, getState) => {
        try {
            dispatch({type: IncidentActionType.SEND_CODE});
            await apiRequest(`/api/incidents/code`, undefined, {email: email}, "POST");
            dispatch({type: IncidentActionType.SEND_CODE_SUCCESS});
        } catch (error) {
            dispatch({
                type: IncidentActionType.SEND_CODE_ERROR,
                payload: {error: `Problem med å sende bekreftelseskode. Vennligst prøv igjen eller kontakt administrator.`}
            });
        }
    },
    addIncident: (incident: Incident, attachments: File[]): AppThunk<AllowedTypes> => async (dispatch, getState) => {
        let incidentCreated: Incident = incident;
        try {
            dispatch({type: IncidentActionType.ADD_ITEM});
            if (incident.id) {
                incidentCreated = incident;
            } else {
                incidentCreated = await apiRequest(`/api/incidents`, getState().oidc.user, incident, "POST");
            }
            try {
                if (attachments) {
                    for (let i = 0; i < attachments.length; i++) {
                        const formData = new FormData();
                        formData.append('file', attachments[i]);
                        debugger;
                        await apiRequest(`/api/incidents/upload/${incidentCreated.id}`, getState().oidc.user, formData, "POST", "");
                    }
                }

                dispatch({type: IncidentActionType.ADD_ITEM_SUCCESS, payload: {incident: incidentCreated}});
            } catch (attError) {
                dispatch({
                    type: IncidentActionType.ADD_ITEM_ERROR,
                    payload: {error: `Saken ble lagret, men vi kunne ikke laste opp vedlegg. Prøv igjen eller legg ved en annen fil. ${attError}`, incident: incidentCreated}
                });
                return;
            }
        } catch (error) {
            dispatch({
                type: IncidentActionType.ADD_ITEM_ERROR,
                payload: {error: `Kunne ikke opprette sak. ${error}`, incident: incidentCreated}
            });
        }
    },
    addIncidentAnon: (incident: Incident, attachments: File[], person?: Person, codeFromEmail?: string): AppThunk<AllowedTypes> => async (dispatch, getState) => {
        let incidentCreated: Incident = incident;
        try {
            dispatch({type: IncidentActionType.ADD_ITEM});
            if (incident.id) {
                incidentCreated = incident;
            } else {
                incidentCreated = await apiRequest(`/api/incidents/anon`, undefined, {
                    incident: incident,
                    person: person,
                    codeFromEmail: codeFromEmail
                }, "POST");
            }

            try {
                if (attachments) {
                    for (let i = 0; i < attachments.length; i++) {
                        const formData = new FormData();
                        formData.append('file', attachments[i]);
                        debugger;
                        await apiRequest(`/api/incidents/anon/upload/${incidentCreated.id}`, getState().oidc.user, formData, "POST", "");
                    }
                }
                dispatch({type: IncidentActionType.ADD_ITEM_SUCCESS, payload: {incident: incidentCreated}});
            } catch (attError) {
                dispatch({
                    type: IncidentActionType.ADD_ITEM_ERROR,
                    payload: {error: `Saken ble lagret, men vi kunne ikke laste opp vedlegg. Prøv igjen eller legg ved en annen fil. ${attError}`, incident: incidentCreated}
                });
                return;
            }
        } catch (error) {
            dispatch({
                type: IncidentActionType.ADD_ITEM_ERROR,
                payload: {error: `Kunne ikke opprette sak. ${error}`, incident: incidentCreated}
            });
        }
    },
    uploadAttachment: (incidentId: string, attachment: File): AppThunk<AllowedTypes> => async (dispatch, getState) => {
        try {
            dispatch({type: IncidentActionType.UPLOAD_ATTACHMENT});

            const user = getState().oidc.user;
            const formData = new FormData();
            formData.append('file', attachment);

            const requestUrl = user ? `/api/incidents/upload/${incidentId}` : `/api/incidents/anon/upload/${incidentId}`;

            await apiRequest(requestUrl, user, formData, "POST", "");

            let {attachments} = getState().incidents;

            dispatch({
                type: IncidentActionType.UPLOAD_ATTACHMENT_SUCCESS,
                payload: {attachments: [...attachments, new Attachment(attachment.name, '')]}
            });
        } catch (error) {
            dispatch({
                type: IncidentActionType.UPLOAD_ATTACHMENT_ERROR,
                payload: {error: `Kunne ikke laste opp saksvedlegg. ${error}`}
            });
        }
    },
    deleteAttachment: (incidentId: string, attachment: Attachment): AppThunk<AllowedTypes> => async (dispatch, getState) => {
        try {
            dispatch({type: IncidentActionType.DELETE_ATTACHMENT});

            const user = getState().oidc.user;

            const requestUrl = user ? `/api/incidents/deletedocument/${incidentId}/${attachment.name}` : `/api/incidents/anon/deletedocument/${incidentId}/${attachment.name}`;

            await apiRequest(requestUrl, user, null, "POST", "");

            let {attachments} = getState().incidents;

            dispatch({
                type: IncidentActionType.DELETE_ATTACHMENT_SUCCESS,
                payload: {attachments: [...attachments.filter(_ => _.name != attachment.name)]}
            });
        } catch (error) {
            dispatch({
                type: IncidentActionType.DELETE_ATTACHMENT_ERROR,
                payload: {error: `Kunne ikke slette saksvedlegg. ${error}`}
            });
        }
    },
};