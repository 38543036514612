import * as React from 'react';
import {Incident, IncidentType} from '../../../store/incidents/commonTypes';
import { Option } from '../../../store/common-values/commonTypes';
import { DataGrid, GridColDef, GridCellParams, nbNO } from '@mui/x-data-grid';
import { Autocomplete } from '@mui/material';
import {
    dateTimeFormatter,
    dateTimeGetter,
    incidentTypeOptions,
    projectFormatter, 
    projectGetter,
    typeFormatter
} from '../../../utils/utils';
import { TextField, FormControl, InputLabel, Select, Button, Link } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import Stack from '@mui/material/Stack';

export interface IncidentsListProps {
    incidents: Incident[];
    incidentStatuses: Option[];
    onOpenNewForm: () => void;
    onOpenDetails: (number: string) => void;
}

export interface IncidentsListState {
    incidents: Incident[];
    incidentStatuses: Option[];
    searchText?: string;
    statusFilter?: string;
    typeFilter?: IncidentType | null;

}

export default class IncidentsList extends React.Component<IncidentsListProps, IncidentsListState> {
    constructor(props: IncidentsListProps) {
        super(props);

        this.state = {
            incidents: props.incidents || [],
            incidentStatuses: props.incidentStatuses || []
        };
    }

    componentDidUpdate(prevProps: IncidentsListProps) {
        if (this.props.incidents?.length !== prevProps.incidents?.length) {
            this.setState({ incidents: this.props.incidents || [] });
        }

        if (this.props.incidentStatuses?.length !== prevProps.incidentStatuses?.length) {
            this.setState({ incidentStatuses: this.props.incidentStatuses || [] });
        }
    }

    private filterIncident(incident: Incident): boolean {
        if (this.state.searchText) {
            if (`${incident.name}  ${incident.number}  ${incident.status}  ${incident.breeamVersion}`.toLowerCase().indexOf(this.state.searchText.toLowerCase()) == -1) return false;
        }

        if (this.state.statusFilter) {
            if (incident.status != this.state.statusFilter) return false;
        }

        if (this.state.typeFilter) {
            if (incident.type != this.state.typeFilter) return false;
        }

        return true;
    }

    public render() {
        const columns: GridColDef[] = [
            {
                field: 'name', headerName: 'Sakstittel', width: 300,
                renderCell: (params: GridCellParams) => (
                    <Link href="#" onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
                        e.preventDefault();
                        this.props.onOpenDetails((params.row as Incident).number);
                    }
                    } >{params.formattedValue}</Link>
                ),
            },
            { field: 'number', headerName: 'Saksnummer', width: 150 },
            { field: 'type', headerName: 'Type', width: 150, valueFormatter: typeFormatter, filterable: false },
            { field: 'breeamVersion', headerName: 'BREEAM-NOR versjon', width: 150 },
            { field: 'project', headerName: 'Prosjekt', width: 150, valueGetter: projectGetter, valueFormatter: projectFormatter },
            { field: 'created', headerName: 'Dato opprettet', width: 150, valueGetter: dateTimeGetter, valueFormatter: dateTimeFormatter },
            { field: 'modified', headerName: 'Siste endringer', width: 150, valueGetter: dateTimeGetter, valueFormatter: dateTimeFormatter },
            { field: 'status', headerName: 'Status', width: 150 }
        ];

        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-sm-3 mb-3">
                        <TextField
                            label="Søk"
                            type="search"
                            fullWidth
                            variant="standard"
                            value={this.state.searchText}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.setState({ searchText: event.target.value })}
                        />
                    </div>
                    <div className="col-sm-3 mb-3">
                        <Autocomplete
                            options={this.state.incidentStatuses}
                            getOptionLabel={(option: Option) => option.label}
                            onChange={(e, status) => this.setState({ statusFilter: status?.label })}
                            renderInput={(params) => <TextField {...params} variant="standard" label="Filtrer etter statuser" />}
                        />
                    </div>
                    <div className="col-sm-3 mb-3">
                        <Autocomplete
                            options={[IncidentType.TechnicalQuery, IncidentType.GeneralQuestion]}
                            getOptionLabel={(option: IncidentType) => incidentTypeOptions.filter(_ => _.id == option)[0].label }
                            onChange={(e, type) => this.setState({ typeFilter: type })}
                            renderInput={(params) => <TextField {...params} variant="standard" label="Filtrer etter typer" />}
                        />
                    </div>
                    <div className="col-sm-3 mb-3 d-flex align-items-center justify-content-end">
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={(event: React.MouseEvent<HTMLButtonElement, Event>) => this.props.onOpenNewForm()}
                            startIcon={<AddIcon />}
                        >
                            Legg til sak
                        </Button>
                    </div>
                </div>
                <div className="table-container">
                    <DataGrid 
                        columns={columns} 
                        rows={this.state.incidents.filter(i => this.filterIncident(i))} 
                        hideFooter 
                        hideFooterPagination 
                        autoHeight 
                        disableSelectionOnClick={true}
                        localeText={nbNO.components.MuiDataGrid.defaultProps.localeText}
                    />
                </div>
            </React.Fragment >
        );
    }
}
