import {Link} from '@mui/material';
import * as React from 'react';
import {connect} from 'react-redux';
import {RootState} from '../store';
import userManager from '../utils/userManager';
import HelpIcon from '@mui/icons-material/Help';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import SchoolIcon from '@mui/icons-material/School';
import ConstructionIcon from '@mui/icons-material/Construction';

export class Home extends React.PureComponent<{ isAuthenticated?: boolean }> {
    public render() {
        const {isAuthenticated} = this.props;

        const onLoginButtonClick = (e: any) => {
            e.preventDefault();
            userManager.signinRedirect({
                data: {
                    path: "/incidents/new"
                }
            });
        };

        return (
            <>
                <div className="full-width home-banner">
                    <div className="pt-5 pb-5 container">
                        <h1>Velkommen til vår portal BREEAMhjelp</h1>
                        <p className="large-text">Har du spørsmål om BREEAM-NOR? Kontakt oss gjerne dersom du ikke finner svar under <Link href={"https://byggalliansen.no/sertifisering/om-breeam/manual-verktoy-og-hjelp/breeam-nor-faq/"}>ofte stilte spørsmål</Link>.</p>
                        <p className="large-text">Vi besvarer alle henvendelser fortløpende. Vi har nå en svarfrist på opptil <b>21 dager</b> for generelle spørsmål, og <b>14 dager</b> for tekniske avklaringer. I ferieperioder kan svarfristen forlenges. </p>
                    </div>
                </div>

                <div>
                    <div className="row mb-3 pt-5 pb-5">
                        <div className="col-sm-6 pt-5 text-center">
                            {isAuthenticated ? (
                                <Link href="/incidents/new" class="tile-link">
                                    <div className="tile-link-content">
                                        <SupportAgentIcon/>
                                        <span>Jeg er REVISOR og skal sende en teknisk avklaring</span>
                                    </div>
                                </Link>
                            ) : (
                                <Link href="#" onClick={onLoginButtonClick} class="tile-link">
                                    <div className="tile-link-content">
                                        <SupportAgentIcon/>
                                        <span>Jeg er REVISOR og skal sende en teknisk avklaring</span>
                                        <p>Logg inn</p>
                                    </div>
                                </Link>
                            )}
                        </div>
                        <div className="col-sm-6 pt-5 text-center">
                            <Link href="/questions/new" class="tile-link">
                                <div class="tile-link-content">
                                    <HelpIcon/>
                                    <span>Jeg har et generelt spørsmål</span>
                                </div>
                            </Link>
                        </div>
                    </div>

                    <div className="row mb-3 pt-5 pb-5">
                        <div className="col-sm-6 pt-5 text-center">
                            <Link href="https://byggalliansen.no/sertifisering/om-breeam/manual-verktoy-og-hjelp/breeam-nor-faq" class="tile-link">
                                <div className="tile-link-content">
                                    <SchoolIcon/>
                                    <span>Ofte stilte spørsmål</span>
                                </div>
                            </Link>
                        </div>
                        <div className="col-sm-6 pt-5 text-center">
                            <Link href="https://byggalliansen.no/sertifisering/om-breeam-in-use/breeam-in-use-manual-verktoy-og-hjelp" class="tile-link">
                                <div className="tile-link-content">
                                    <ConstructionIcon/>
                                    <span>BREEAM In-Use</span>
                                </div>
                            </Link>
                        </div>
                    </div>

                    <h3 className="pt-3">Generelle spørsmål om BREEAM-NOR</h3>
                    <p>Har du et teknisk spørsmål eller et annet generelt spørsmål om BREEAM-NOR kan du benytte skjemaet ovenfor eller sende en e-post til <Link href={"mailto:BREEAMhjelp@byggalliansen.no"}>BREEAMhjelp@byggalliansen.no</Link>.</p>
                    <p>Eksempler på slike spørsmål er om du trenger hjelp til tolkning av kriterier, synes manualen er uklar eller du har oppdaget feil i manualen eller tilhørende verktøy</p>

                    <h3 className="pt-3">Spørsmål om sertifiseringsordningen BREEAM </h3>
                    <p>Ved spørsmål om BREEAM som sertifiseringsordning, send e-post til <Link href={"mailto:sertifisering@byggalliansen.no"}>sertifisering@byggalliansen.no</Link>. Spørsmål om QA kan også sendes hit. </p>
                    <p>Henvendelser angående Bespoke (tilpassede kriteriesett) rettes til <Link href={"mailto:bespoke@byggalliansen.no"}>bespoke@byggalliansen.no</Link>.</p>
                    <p>For spørsmål om sertifiseringsordningen BREEAM In-Use se vår <Link href={"https://byggalliansen.no/sertifisering/om-breeam-in-use/breeam-in-use-kontaktinformasjon-verktoy-og-hjelp/"}>nettside for BREEAM In-Use</Link>.</p>

                    <h3 className="pt-3">Tekniske avklaringer</h3>
                    <p>En teknisk avklaring er en forespørsel fra revisor om en avvikstillatelse. De benyttes der et spesifikt prosjekt ønsker å vise samsvar med et kriterium på en alternativ metode enn spesifisert i manualen. Det må kunne dokumenteres at emnets formål blir oppfylt med tilsvarende kvalitet som manualen krever med den foreslåtte løsningen.</p>
                    <p>BREEAM-NOR er en belønningsstandard for bærekraftskvaliteter. Det er disse kvalitetene som danner grunnlaget for å oppnå poeng. Om kvaliteten ikke er til stede i bygget vil ikke teknisk avklaring kunne godkjennes.</p>
                    <p>Tekniske avklaringer kan kun sendes inn av prosjektets registrerte revisor. Revisor skal først vurdere prosjektets dokumentasjon, og deretter oversende dette til Grønn Byggallianse med sin vurdering.</p> 
                    <p>Det må opprettes separate saker for hver teknisk avklaring. To tekniske avklaringer fra samme prosjekt må eksempelvis registreres hver for seg.</p>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state: RootState) => {
    return {
        isAuthenticated: state.oidc.user && !state.oidc.user.expired
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        dispatch
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Home);