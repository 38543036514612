export const CommonValuesActionType = {
    UPDATE_INCIDENT_STATUSES: 'incident/statuses/update',
    UPDATE_BREEAM_VERSIONS: 'breeam/versions/update',
    UPDATE_BREEAM_SUBJECTS: 'breeam/subjects/update',
    UPDATE_REGIONS: 'breeam/regions/update',
    UPDATE_BUILDING_TYPES: 'breeam/building_types/update',
    UPDATE_PROJECT_TYPES: 'breeam/project_types/update',
    UPDATE_SIZE_TYPES: 'breeam/size_types/update',
    UPDATE_AUDIT_PHASES: 'breeam/audit_phases/update',
    UPDATE_FURNISHED_STATUSES: 'breeam/furnished_statuses/update',
    UPDATE_LEVELS: 'breeam/levels/update',
    REQUEST_ERROR: 'values/fetch/error'
};

export interface CommmonValuesState {
    incidentStatuses: Option[];
    projectStatuses: Option[];
    breeamVersions: Option[];
    regions: Option[];
    breeamSubjects: Subject[];
    buildingTypes: Option[];
    projectTypes: Option[];
    sizeTypes: Option[];
    auditPhases: Option[];
    furnishedStatuses: Option[];
    levels: Option[];
}

export interface Option {
    id: number;
    label: string;
}

export interface Subject {
    id: string;
    title: string;
    parentId: string;
    parent?: Subject;
}

export interface Company {
    id: string;
    name: string;
    orgNumber: string;
    email: string;
    phone: string;
    addressStreet: string;
    addressPostCode: string;
    addressCity: string;
    addressCountry: string;
}

export interface Person {
    id: string;
    firstName: string;
    lastName: string;
    fullName: string;
    phone: string;
    email: string;
    companyId: string;
    company?: Company;
}

export type AllowedTypes = Option[] | Subject[];