import * as React from 'react';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ApplicationNotification } from '../../../store/runtime';

export interface NotificationProps {
    onClose: () => void;
    notification: ApplicationNotification;
    autoClose: number;
}

export default class IncidentNewForm extends React.Component<NotificationProps> {
    componentDidMount() {
        window.setTimeout(() => this.props.onClose(), this.props.autoClose);
    }

    public render() {
        return (
            <React.Fragment>
                <div className={`notification ${this.props.notification.type} row`}>
                    <div className="col-sm-10">
                        {this.props.notification.text}
                    </div>
                    <div className="col-sm-2">
                        <IconButton size="small" aria-label="close" onClick={() => this.props.onClose()}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                </div>
            </React.Fragment >
        );
    }
}