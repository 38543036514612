import { AppThunk } from '..';
import { AllowedTypes, Comment, CommentsActionType } from './commonTypes';
import { apiRequest } from '../../utils/api';

export const actionCreators = {
    requestIncidentComments: (incidentId: string): AppThunk<AllowedTypes> => async (dispatch, getState) => {
        try {
            dispatch({ type: CommentsActionType.REQUEST_LIST });
            const user = getState().oidc.user;
            const requestUrl = user ? `/api/incidents/${incidentId}/comments` : `/api/incidents/anon/${incidentId}/comments`;
            const data = await apiRequest(requestUrl, user) as Comment[];
            dispatch({ type: CommentsActionType.RECEIVE_LIST, payload: { comments: data } });
        } catch (error) {
            dispatch({ type: CommentsActionType.REQUEST_LIST_ERROR, payload: { error: `Could not retrieve incidents's comments. ${error}` } });
        }
    },
    addIncidentComment: (comment: Comment, incidentId: string): AppThunk<AllowedTypes> => async (dispatch, getState) => {
        try {
            dispatch({ type: CommentsActionType.REQUEST_ADD_COMMENT });
            const user = getState().oidc.user;
            const requestUrl = user ? `/api/incidents/${incidentId}/comments/add` : `/api/incidents/anon/${incidentId}/comments/add`;
            await apiRequest(requestUrl, user, comment, "POST");
            dispatch({ type: CommentsActionType.RECEIVE_ADD_COMMENT });
            dispatch({ type: CommentsActionType.RECEIVE_LIST, payload: { comments: [{ ...comment, created: new Date() }, ...getState().comments.comments] } });
        } catch (error) {
            dispatch({ type: CommentsActionType.REQUEST_ADD_COMMENT_ERROR, payload: { error: `Could not post comment. ${error}` } });
        }
    },
    requestCertificationComments: (certificationId: string): AppThunk<AllowedTypes> => async (dispatch, getState) => {
        try {
            dispatch({ type: CommentsActionType.REQUEST_LIST });
            const data = await apiRequest(`/api/certification/${certificationId}/comments`, getState().oidc.user) as Comment[];
            dispatch({ type: CommentsActionType.RECEIVE_LIST, payload: { comments: data } });
        } catch (error) {
            dispatch({ type: CommentsActionType.REQUEST_LIST_ERROR, payload: { error: `Could not retrieve certification's comments. ${error}` } });
        }
    },
    addCertificationComment: (comment: Comment, certificationId: string): AppThunk<AllowedTypes> => async (dispatch, getState) => {
        try {
            dispatch({ type: CommentsActionType.REQUEST_ADD_COMMENT });
            await apiRequest(`/api/certification/${certificationId}/comments/add`, getState().oidc.user, comment, "POST");
            dispatch({ type: CommentsActionType.RECEIVE_ADD_COMMENT });
            dispatch({ type: CommentsActionType.RECEIVE_LIST, payload: { comments: [{ ...comment, created: new Date() }, ...getState().comments.comments] } });
        } catch (error) {
            dispatch({ type: CommentsActionType.REQUEST_ADD_COMMENT_ERROR, payload: { error: `Could not post comment. ${error}` } });
        }
    },
    requestProjectComments: (projectId: string): AppThunk<AllowedTypes> => async (dispatch, getState) => {
        try {
            dispatch({ type: CommentsActionType.REQUEST_LIST });
            const data = await apiRequest(`/api/projects/${projectId}/comments`, getState().oidc.user) as Comment[];
            dispatch({ type: CommentsActionType.RECEIVE_LIST, payload: { comments: data } });
        } catch (error) {
            dispatch({ type: CommentsActionType.REQUEST_LIST_ERROR, payload: { error: `Could not retrieve project's comments. ${error}` } });
        }
    },
    addProjectComment: (comment: Comment, projectId: string): AppThunk<AllowedTypes> => async (dispatch, getState) => {
        try {
            dispatch({ type: CommentsActionType.REQUEST_ADD_COMMENT });
            await apiRequest(`/api/projects/${projectId}/comments/add`, getState().oidc.user, comment, "POST");
            dispatch({ type: CommentsActionType.RECEIVE_ADD_COMMENT });
            dispatch({ type: CommentsActionType.RECEIVE_LIST, payload: { comments: [{ ...comment, created: new Date() }, ...getState().comments.comments] } });
        } catch (error) {
            dispatch({ type: CommentsActionType.REQUEST_ADD_COMMENT_ERROR, payload: { error: `Could not post comment. ${error}` } });
        }
    },
    requestAuditComments: (auditId: string): AppThunk<AllowedTypes> => async (dispatch, getState) => {
        try {
            dispatch({ type: CommentsActionType.REQUEST_LIST });
            const data = await apiRequest(`/api/certification/audits/${auditId}/comments`, getState().oidc.user) as Comment[];
            dispatch({ type: CommentsActionType.RECEIVE_LIST, payload: { comments: data } });
        } catch (error) {
            dispatch({ type: CommentsActionType.REQUEST_LIST_ERROR, payload: { error: `Could not retrieve audit's comments. ${error}` } });
        }
    },
    addAuditComment: (comment: Comment, auditId: string): AppThunk<AllowedTypes> => async (dispatch, getState) => {
        try {
            dispatch({ type: CommentsActionType.REQUEST_ADD_COMMENT });
            await apiRequest(`/api/certification/audits/${auditId}/comments/add`, getState().oidc.user, comment, "POST");
            dispatch({ type: CommentsActionType.RECEIVE_ADD_COMMENT });
            dispatch({ type: CommentsActionType.RECEIVE_LIST, payload: { comments: [{ ...comment, created: new Date() }, ...getState().comments.comments] } });
        } catch (error) {
            dispatch({ type: CommentsActionType.REQUEST_ADD_COMMENT_ERROR, payload: { error: `Could not post comment. ${error}` } });
        }
    }
};